export interface Environment {
  [key: string]: string
}
export const environment: Environment = Object.keys(process.env).reduce((map, attr) => {
  const val = process.env[attr]

  if (val && /(true|false)/i.test(val)) {
    map[attr.replace('VUE_APP_', '')] = JSON.parse(val)
  } else if (val) {
    map[attr.replace('VUE_APP_', '')] = val
  }

  return map
}, {} as { [key: string]: any })
