/* eslint-disable import/no-cycle */
import Vue from 'vue'
import Router, { Route } from 'vue-router'
import logger from '@/utils/logger'
import routeHandler from '@/utils/navguards'
import { PopStatePlugin } from '@/utils/popstateplugin'
import { eventbus, eventBusEvents } from '@fiizy/utils'
import { environment } from '@/environments/environment'
import FormRequest from '@/models/formrequest'
import TrackingModule from '@/store/modules/tracking'
import redirectHome from '@/utils/events/redirectHome'
import AuthModule from '@/store/modules/auth'
import ProcessModule from '@/store/modules/process'
import AttributionModule from '@/store/modules/attribution'
import { FormRequestFields } from '@/models'
import { objectToFormRequestFields } from '@/utils/helpers'

const bus = eventbus(environment.EVENT_CHANNEL || 'c432556660722ff93cefb5a932e550b7')
const { appLoaded, processStart, processReset } = eventBusEvents

// PopStatePlugin has to be registered first so that we can use popstate in router hooks
Vue.use(PopStatePlugin)
Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'calculator',
      component: () => import(/* webpackChunkName: "process" */ './views/Process.vue'),
      beforeEnter: (to, from, next) => {
        AttributionModule.resetProcess()
        document.body.classList.remove('process')
        bus.emit(processReset)
        next()
      }
    },
    {
      // this route has to be before 'processIncomplete' route to match path
      // example: /application/xxx/process/CAMPAIGN_RESUME
      path: '/application/:applicationHash/process/resume/:processName',
      name: 'processResume',
      component: () => import(/* webpackChunkName: "process" */ './views/Process.vue'),
      beforeEnter: async (to, from, next) => {
        if (to.params && to.params.applicationHash && to.params.processName) {
          const attribution = {
            applicationHash: to.params.applicationHash,
            processName: to.params.processName
          }

          const map: FormRequestFields = objectToFormRequestFields(to.query)

          if (map.auth_link_hash && map.auth_link_hash !== '') {
            const authLoginResponse = await AuthModule.authLogin({
              channelHash: AttributionModule.getChannelHash as string,
              grantType: 'magic_link',
              authLinkHash: map.auth_link_hash as string
            })

            if (map.auth_link_hash) delete map.auth_link_hash
            if (!authLoginResponse) redirectHome()
          }

          const formRequest: FormRequest = {
            attribution,
            form: map
          }
          TrackingModule.fetchImpressionHash(attribution).then((impressionHash) => {
            ProcessModule.resumeProcess({
              ...formRequest,
              attribution: { ...attribution, impressionHash: impressionHash || '' }
            }).then((form) => {
              if (!form) {
                redirectHome()
                ProcessModule.resetApplication()
              } else {
                bus.emit(appLoaded, {})
                next({
                  name: 'process',
                  params: {
                    processName: form.attribution?.processName as string,
                    processSessionHash: form.attribution?.processSessionHash as string
                  },
                  query: to.query
                })
              }
            })
          })
        } else {
          // we need to refresh page to get to home CMS route
          redirectHome()
        }
      }
    },
    {
      // this route has to be before 'process' route to match path
      // example:
      // /application/2c7412c8263cccf9d965d2eeb387dff97ad93f40/process/REM_RESUME_APPLICATION?xc=4715d095fdf2b6059010baf72171b9da464e8881
      path: '/application/:applicationHash/process/:processName',
      name: 'processIncomplete',
      component: () => import(/* webpackChunkName: "process" */ './views/Process.vue'),
      beforeEnter: async (to, from, next) => {
        if (to.params && to.params.applicationHash && to.params.processName && to.query && to.query.xc) {
          const attribution = {
            channelHash: to.query.xc as string,
            applicationHash: to.params.applicationHash,
            processName: to.params.processName
          }

          if (to.query.auth_link_hash && to.query.auth_link_hash !== '') {
            const authLoginResponse = await AuthModule.authLogin({
              channelHash: to.query.xc as string,
              grantType: 'magic_link',
              authLinkHash: to.query.auth_link_hash as string
            })

            if (!authLoginResponse) redirectHome()
          }

          const formRequest: FormRequest = {
            attribution
          }
          TrackingModule.fetchImpressionHash(attribution).then((impressionHash) => {
            ProcessModule.initProcess({
              ...formRequest,
              attribution: { ...attribution, impressionHash: impressionHash || '' }
            }).then((form) => {
              if (!form) {
                redirectHome()
                ProcessModule.resetApplication()
              } else {
                bus.emit(appLoaded, {})
                next({
                  name: 'process',
                  params: {
                    processName: form.attribution?.processName as string,
                    processSessionHash: form.attribution?.processSessionHash as string
                  },
                  query: to.query
                })
              }
            })
          })
        } else {
          // we need to refresh page to get to home CMS route
          redirectHome()
        }
      }
    },

    {
      path: '/admin/process/:processSessionHash/:timestamp?',
      name: 'processAdmin',
      component: () => import(/* webpackChunkName: "process" */ './views/Process.vue'),
      beforeEnter: (to, from, next) => {
        if (!to.params.processSessionHash) {
          logger.error('Process missing processSessionHash param, navigating back to /', { to, from, next })
          next({ name: 'calculator', query: to.query })
        }

        document.body.classList.add('process')
        bus.emit(processStart)

        next({
          query: to.query
        })
      }
    },
    {
      path: '/process/:processSessionHash/:timestamp?',
      name: 'process',
      component: () => import(/* webpackChunkName: "process" */ './views/Process.vue'),
      beforeEnter: (to, from, next) => {
        if (!to.params.processSessionHash) {
          logger.error('Process missing processSessionHash param, navigating back to /', { to, from, next })
          next({ name: 'calculator', query: to.query })
        }

        document.body.classList.add('process')
        bus.emit(processStart)

        next({
          query: to.query
        })
      }
    },
    {
      path: '/login/:processSessionHash?/:timestamp?',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
      beforeEnter: async (to, from, next) => {
        document.body.classList.add('self-service')
        bus.emit(processStart)
        if (await AuthModule.isUserAuth()) return next('profile')

        return next()
      }
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import(/* webpackChunkName: "profile" */ './views/Profile.vue'),
      beforeEnter: async (to, from, next) => {
        document.body.classList.add('profile-service')
        bus.emit(processStart)
        if (await AuthModule.isUserAuth()) return next()

        return next('login')
      }
    },
    {
      name: 'default',
      path: '*',
      component: () => import(/* webpackChunkName: "process" */ './views/Process.vue')
    }
  ],
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})

// attach route handler, has to be before popstate reset
router.beforeEach(routeHandler(router))

// reset popstate globally
router.beforeEach((to: Route, from: Route, next: any) => {
  next()

  Vue.prototype.popstate = false
})

export default router
