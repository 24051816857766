function isValidSpainCompanyIdCode(spainCompanyIdCode: string): boolean {
  if (!spainCompanyIdCode || spainCompanyIdCode.length !== 9) {
    return false
  }

  const letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']
  const digits = spainCompanyIdCode.substring(1, spainCompanyIdCode.length - 2)
  const letter = spainCompanyIdCode.substring(0, 1)
  const control = spainCompanyIdCode.substring(spainCompanyIdCode.length - 1)
  let sum = 0
  let i
  let digit

  if (!letter.match(/[A-Z]/)) {
    return false
  }

  for (i = 0; i < digits.length; i += 1) {
    digit = parseInt(digits[i], 10)

    if (Number.isNaN(digit)) {
      return false
    }

    if (i % 2 === 0) {
      digit *= 2
      if (digit > 9) {
        digit = parseInt((digit / 10).toString(), 10) + (digit % 10)
      }

      sum += digit
    } else {
      sum += digit
    }
  }

  sum %= 10
  if (sum !== 0) {
    digit = 10 - sum
  } else {
    digit = sum
  }

  if (letter.match(/[ABEH]/)) {
    return String(digit) === control
  }
  if (letter.match(/[NPQRSW]/)) {
    return letters[digit] === control
  }

  return String(digit) === control || letters[digit] === control
}

export default isValidSpainCompanyIdCode
