/* eslint-disable import/no-cycle */
import { environment } from '@/environments/environment'
import { Fitch, transformToCamel, transformToSnake } from '@fiizy/fitch'
import AuthModule from '@/store/modules/auth'
import ProcessModule from '@/store/modules/process'
import apiUrl from '@/utils/apiUrl'
import { JsonObject } from '@/models/JsonObject'
import clientData from '@/utils/clientData'

function addClientData(request: JsonObject): JsonObject {
  if (request.body) {
    request.body = { ...request.body, ...clientData() }
  }

  return request
}

export function addFormValidationToken(request: JsonObject): JsonObject {
  if (request.body && ProcessModule.getFormValidationToken) {
    request.body.formValidationToken = ProcessModule.getFormValidationToken
  }

  return request
}

function authHeader(request: JsonObject): JsonObject {
  if (AuthModule && AuthModule.getAccessToken) {
    request.headers = { ...request.headers, Authorization: `Bearer ${AuthModule.getAccessToken}` }
  }
  return request
}

const apiService = new Fitch({
  baseURL: apiUrl() + environment.BASE_PATH,
  transformRequest: [addFormValidationToken, addClientData, transformToSnake, authHeader],
  transformResponse: [transformToCamel]
})

export default apiService
