export default function spainBankAccountNumberValidator(fieldValue: string): boolean {
  const checkCodeWeights = [1, 2, 4, 8, 5, 10, 9, 7, 3, 6]
  const matches = fieldValue.match(/^([0-9]{8,8})([0-9]{2,2})([0-9]{10,10})$/)

  if (!matches) {
    return false
  }

  const firstCode = `00${matches[1]}`
  const controlCode = matches[2]
  const accountNumber = matches[3]

  let firstCodeResult = 0

  for (let x = 0; x < 10; x += 1) {
    firstCodeResult += parseInt(firstCode.charAt(x), 10) * checkCodeWeights[x]
  }

  const firstCodeMod = firstCodeResult % 11

  firstCodeResult = 11 - firstCodeMod

  if (firstCodeResult === 10) {
    firstCodeResult = 1
  }

  if (firstCodeResult === 11) {
    firstCodeResult = 0
  }

  let secondCodeResult = 0

  for (let x = 0; x < 10; x += 1) {
    secondCodeResult += parseInt(accountNumber[x], 10) * checkCodeWeights[x]
  }

  const secondCodeMod = secondCodeResult % 11

  secondCodeResult = 11 - secondCodeMod

  if (secondCodeResult === 10) {
    secondCodeResult = 1
  }

  if (secondCodeResult === 11) {
    secondCodeResult = 0
  }

  if (`${firstCodeResult}${secondCodeResult}` === controlCode) {
    return true
  }

  return false
}
