import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '@/store'
import { Attribution, Form, Notification, NotificationQueueActionRequest, NotificationSubscribeRequest } from '@/models'
import {
  fetchNotificationQueue,
  patchNotificationQueueAction,
  postSubscribeAction
} from '@/api/resources/notifications'

@Module({
  dynamic: true,
  store,
  namespaced: true,
  name: 'notifications'
})
class NotificationsStore extends VuexModule {
  notifications: Notification[] = []

  get getNotifications(): Notification[] {
    return this.notifications
  }

  @Mutation
  private SET_NOTIFICATIONS(notifications: Notification[]) {
    this.notifications = notifications
  }

  @Action({ commit: 'SET_NOTIFICATIONS' })
  setNotifications(notifications: Notification[]): Notification[] {
    return notifications
  }

  @Action({ rawError: true, commit: 'SET_NOTIFICATIONS' })
  async fetchNotifications(): Promise<Notification[]> {
    const res = await fetchNotificationQueue()

    if (res) {
      store.dispatch('attribution/setNotifications', res)
      return res
    }

    return this.notifications
  }

  @Action
  async patchNotificationQueueAction({
    queueHash,
    status
  }: {
    queueHash: string
    status: string
  }): Promise<Form | undefined> {
    const request = {
      queueHash,
      form: {
        status
      }
    } as NotificationQueueActionRequest

    return patchNotificationQueueAction(request)
  }

  @Action
  async subscribeAction({
    token,
    attribution
  }: {
    token: string
    attribution: Attribution
  }): Promise<Form | undefined> {
    const request = {
      attribution,
      token
    } as unknown as NotificationSubscribeRequest

    return postSubscribeAction(request)
  }
}

const NotificationsModule = getModule(NotificationsStore, store)

export default NotificationsModule
