import { FormField, FieldEvent } from '@/models'
import { capitalize, toCamel } from '@/utils/transformer'

export function sortRows(formFields: FormField[]): FormField[][] {
  // get the highest row, default is 1
  const numRows: number = Math.max(...formFields.map((field) => field.row))

  // map rows on arrays
  const sortedRows = formFields.reduce((rows: FormField[][], field: FormField) => {
    const rowIndex = field.row - 1

    if (rows[rowIndex]) {
      rows[rowIndex].push(field)
    } else {
      rows[rowIndex] = [field]
    }

    const rowFieldsHaveOrderNumbers = rows[rowIndex].every(({ order }) => typeof order !== 'undefined')

    if (rowFieldsHaveOrderNumbers && rows[rowIndex].length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      rows[rowIndex] = rows[rowIndex].sort((a, b) => a.order! - b.order!)
    }

    return rows
  }, new Array<FormField[]>(numRows))

  // sometimes rows are left out by the api
  for (let index = 0; index < sortedRows.length; index += 1) {
    if (!sortedRows[index]) {
      sortedRows[index] = []
    }
  }

  return sortedRows
}

export function getFormFieldComponentName(field: FormField): string {
  return 'FormItem'.concat(capitalize(toCamel(field.fieldType)))
}

export function getEventByAction(field: FormField, eventAction: string): FieldEvent | undefined {
  if (!field.events) {
    return undefined
  }

  const event = field.events.find((e) => e.action === eventAction)

  if (event && event.dataSource) {
    return event
  }

  return undefined
}

export function getEventsByAction(field: FormField, eventAction: string): FieldEvent[] {
  if (!field.events) {
    return []
  }

  return field.events.filter((e) => e.action === eventAction)
}

export function sortFormFields(a: FormField, b: FormField): number {
  return a.row - b.row || a.order - b.order
}
