import clabe from '@/utils/mx/clabe'

function mexicoBankAccountNumberValidator(fieldValue: string): boolean {
  if (!fieldValue) {
    return false
  }

  return clabe.validate(fieldValue).ok
}

export default mexicoBankAccountNumberValidator
