// https://github.com/center-key/clabe-validator
type ClabeBank = { tag?: string; name?: string }
type ClabeBanksMap = { [bankCode: number]: ClabeBank }
type ClabeCity = [number, string]
type ClabeCitiesMap = { [cityCode: number]: string }
type ClabeCheck = {
  ok: boolean
  formatOk: boolean
  error: string | null
  message: string
  clabe: string | null
  tag: string | null
  bank: string | null
  city: string | null
  account: string
  code: { bank: string; city: string }
  checksum: number | null
}

const clabe = {
  computeChecksum(clabeNum17: string): number | null {
    // Returns the checksum calculated from the first 17 characters of CLABE number.
    // Example:
    //    const checksum = clabe.computeChecksum('00201007777777777');  //value: 1
    const x = (i: number): number => <number>[3, 7, 1][i % 3]
    const add = (sum: number, digit: string, i: number) => sum + ((Number(digit) * x(i)) % 10)
    const compute = () => (10 - (clabeNum17.split('').slice(0, 17).reduce(add, 0) % 10)) % 10
    return /^[0-9]{17,18}$/.test(clabeNum17) ? compute() : null
  },

  validate(clabeNum: string): ClabeCheck {
    // Returns information in a map (object literal) about the CLABE number.
    // Example:
    //    const city = clabe.validate('002010077777777771').city;  //value: "Banco Nacional de México"
    const errorMap: { [code: string]: string } = {
      length: 'Must be exactly 18 digits long',
      characters: 'Must be only numeric digits (no letters)',
      checksum: 'Invalid checksum, last digit should be: ',
      bank: 'Invalid bank code: ',
      city: 'Invalid city code: '
    }

    const bankCode = clabeNum.substring(0, 3)
    const cityCode = clabeNum.substring(3, 6)
    const account = clabeNum.substring(6, 17)
    const checksum = Number(clabeNum.substring(17, 18))
    const makeCitiesMap = () => {
      const prefix = (code: number): string => (clabe.citiesMap[code] ? `${clabe.citiesMap[code]}, ` : '')
      function addCity(city: ClabeCity) {
        // 0: code, 1: name
        clabe.citiesMap[city[0]] = prefix(city[0]) + city[1]
      }
      clabe.cities.forEach(addCity)
    }
    if (!clabe.citiesMap[(<ClabeCity>clabe.cities[0])[0]]) makeCitiesMap()
    const bank: ClabeBank = clabe.banksMap[Number(bankCode)] || {}
    const city = clabe.citiesMap[Number(cityCode)]
    const realChecksum = clabe.computeChecksum(clabeNum)
    const getValidationInfo = (): { invalid: string; data: string | number } | null =>
      // eslint-disable-next-line no-nested-ternary
      clabeNum.length !== 18
        ? { invalid: 'length', data: '' }
        : // eslint-disable-next-line no-nested-ternary
        /[^0-9]/.test(clabeNum)
        ? { invalid: 'characters', data: '' }
        : // eslint-disable-next-line no-nested-ternary
        checksum !== realChecksum
        ? { invalid: 'checksum', data: <number>realChecksum }
        : // eslint-disable-next-line no-nested-ternary
        !bank.tag
        ? { invalid: 'bank', data: bankCode }
        : !city
        ? null // ? { invalid: 'city', data: cityCode } disabled as cities list is not up-to-date
        : null
    const validation = getValidationInfo()
    return {
      ok: !validation,
      formatOk: !validation || ['bank', 'city'].includes(validation.invalid),
      error: validation ? `invalid-${validation.invalid}` : null,
      message: validation ? <string>errorMap[validation.invalid] + validation.data : 'Valid',
      clabe: validation ? null : clabeNum,
      tag: bank.tag || null,
      bank: bank.name || null,
      city: city || null,
      account,
      code: { bank: bankCode, city: cityCode },
      checksum: realChecksum
    }
  },

  banksMap: <ClabeBanksMap>{
    // Sources:
    //    https://es.wikipedia.org/wiki/CLABE#C.C3.B3digo_de_banco
    //    http://omawww.sat.gob.mx/fichas_tematicas/buzon_tributario/Documents/catalogo_bancos.pdf
    //    https://frbservices.org/assets/financial-services/ach/global-service-orig-manual.pdf
    //    https://www.banxico.org.mx/cep-scl/listaInstituciones.do
    1: { tag: 'BANXICO', name: 'Banco de México' },
    2: { tag: 'BANAMEX', name: 'Banco Nacional de México' },
    6: { tag: 'BANCOMEXT', name: 'Banco Nacional de Comercio Exterior' },
    9: { tag: 'BANOBRAS', name: 'Banco Nacional de Obras y Servicios Públicos' },
    12: { tag: 'BBVA BANCOMER', name: 'BBVA Bancomer' },
    14: { tag: 'SANTANDER', name: 'Banco Santander' },
    19: { tag: 'BANJERCITO', name: 'Banco Nacional del Ejército, Fuerza Aérea y Armada' },
    21: { tag: 'HSBC', name: 'HSBC México' },
    22: { tag: 'GE MONEY', name: 'GE Money Bank' },
    30: { tag: 'BAJIO', name: 'Banco del Bajío' },
    32: { tag: 'IXE', name: 'IXE Banco' },
    36: { tag: 'INBURSA', name: 'Banco Inbursa' },
    37: { tag: 'INTERACCIONES', name: 'Banco Interacciones' },
    42: { tag: 'MIFEL', name: 'Banca Mifel' },
    44: { tag: 'SCOTIABANK', name: 'Scotiabank Inverlat' },
    58: { tag: 'BANREGIO', name: 'Banco Regional de Monterrey' },
    59: { tag: 'INVEX', name: 'Banco Invex' },
    60: { tag: 'BANSI', name: 'Bansi' },
    62: { tag: 'AFIRME', name: 'Banca Afirme' },
    72: { tag: 'BANORTE', name: 'Banco Mercantil del Norte' },
    102: { tag: 'ACCENDO BANCO', name: 'ABN AMRO Bank México' },
    103: { tag: 'AMERICAN EXPRESS', name: 'American Express Bank (México)' },
    106: { tag: 'BAMSA', name: 'Bank of America México' },
    108: { tag: 'TOKYO', name: 'Bank of Tokyo-Mitsubishi UFJ (México)' },
    110: { tag: 'JP MORGAN', name: 'Banco J.P. Morgan' },
    112: { tag: 'BMONEX', name: 'Banco Monex' },
    113: { tag: 'VE POR MAS', name: 'Banco Ve por Mas' },
    116: { tag: 'ING', name: 'ING Bank (México)' },
    124: { tag: 'DEUTSCHE', name: 'Deutsche Bank México' },
    126: { tag: 'CREDIT SUISSE', name: 'Banco Credit Suisse (México)' },
    127: { tag: 'AZTECA', name: 'Banco Azteca' },
    128: { tag: 'AUTOFIN', name: 'Banco Autofin México' },
    129: { tag: 'BARCLAYS', name: 'Barclays Bank México' },
    130: { tag: 'COMPARTAMOS', name: 'Banco Compartamos' },
    131: { tag: 'FAMSA', name: 'Banco Ahorro Famsa' },
    132: { tag: 'BMULTIVA', name: 'Banco Multiva' },
    133: { tag: 'ACTINVER', name: 'Banco Actinver' },
    134: { tag: 'WAL-MART', name: 'Banco Wal-Mart de México Adelante' },
    135: { tag: 'NAFIN', name: 'Nacional Financiera' },
    136: { tag: 'INTERBANCO', name: 'Inter Banco' },
    137: { tag: 'BANCOPPEL', name: 'BanCoppel' },
    138: { tag: 'ABC CAPITAL', name: 'ABC Capital' },
    139: { tag: 'UBS BANK', name: 'UBS Banco' },
    140: { tag: 'CONSUBANCO', name: 'Banco Fácil' },
    141: { tag: 'VOLKSWAGEN', name: 'Volkswagen Bank, Institución de Banca Múltiple' },
    143: { tag: 'CIBANCO', name: 'Consultoría Internacional Banco' },
    145: { tag: 'BBASE', name: 'Banco BASE' },
    147: { tag: 'BANKAOOL', name: 'Bankaool, Institución de Banca Múltiple' },
    148: { tag: 'PAGATODO', name: 'Banco PagaTodo Institución de Banca Múltiple' },
    150: { tag: 'INMOBILIARIO', name: 'Banco Inmobiliario Mexicano, Institución de Banca Múltiple' },
    151: { tag: 'DONDE', name: 'Fundación Dondé Banco' },
    152: { tag: 'BANCREA', name: 'Banco Bancrea, Institución de Banca Múltiple' },
    154: { tag: 'BANCO FINTERRA', name: 'Banco Finterra' },
    155: { tag: 'ICBC', name: 'Industrial and Commercial Bank of China' },
    156: { tag: 'SABADELL', name: 'Banco de Sabadell' },
    157: { tag: 'SHINHAN', name: 'Shinhan Bank' },
    158: { tag: 'MIZUHO BANK', name: 'Mizuho Bank' },
    159: { tag: 'BANK OF CHINA', name: 'Bank of China México' },
    160: { tag: 'BANCO S3', name: 'Banco S3 México' },
    166: { tag: 'BANSEFI', name: 'Banco del Ahorro Nacional y Servicios Financieros' },
    168: { tag: 'HIPOTECARIA FED', name: 'Sociedad Hipotecaria Federal' },
    600: { tag: 'MONEXCB', name: 'Monex Casa de Bolsa' },
    601: { tag: 'GBM', name: 'GBM Grupo Bursátil Mexicano' },
    602: { tag: 'MASARI', name: 'Masari Casa de Bolsa' },
    604: { tag: 'C.B. INBURSA', name: 'Inversora Bursátil' },
    605: { tag: 'VALUE', name: 'Valué, Casa de Bolsa' },
    606: { tag: 'ESTRUCTURADORES', name: 'Base Internacional Casa de Bolsa' },
    607: { tag: 'TIBER', name: 'Casa de Cambio Tiber' },
    608: { tag: 'VECTOR', name: 'Vector Casa de Bolsa' },
    610: { tag: 'B&B', name: 'B y B Casa de Cambio' },
    611: { tag: 'INTERCAM', name: 'Intercam Casa de Cambio' },
    613: { tag: 'MULTIVA', name: 'Multivalores Casa de Bolsa, Multiva Gpo. Fin.' },
    614: { tag: 'ACCIVAL', name: 'Acciones y Valores Banamex, Casa de Bolsa' },
    615: { tag: 'MERRILL LYNCH', name: 'Merrill Lynch México, Casa de Bolsa' },
    616: { tag: 'FINAMEX', name: 'Casa de Bolsa Finamex' },
    617: { tag: 'VALMEX', name: 'Valores Mexicanos Casa de Bolsa' },
    618: { tag: 'ÚNICA', name: 'Única Casa de Cambio' },
    619: { tag: 'ASEGURADORA MAPFRE', name: 'MAPFRE Tepeyac' },
    620: { tag: 'PROFUTURO', name: 'Profuturo G.N.P., Afore' },
    621: { tag: 'CB ACTINBER', name: 'Actinver Casa de Bolsa' },
    622: { tag: 'ACTINVE SI', name: 'Actinver' },
    623: { tag: 'SKANDIA', name: 'Skandia Vida' },
    624: { tag: 'CONSULTORÍA', name: 'Consultoría Internacional Casa de Cambio' },
    626: { tag: 'CBDEUTSCHE', name: 'Deutsche Securities' },
    627: { tag: 'ZURICH', name: 'Zurich Compañía de Seguros' },
    628: { tag: 'ZURICHVI', name: 'Zurich Vida, Compañía de Seguros' },
    629: { tag: 'HIPOTECARIA SU CASITA', name: 'Hipotecaria su Casita' },
    630: { tag: 'CB INTERCAM', name: 'Intercam Casa de Bolsa' },
    631: { tag: 'CI BOLSA', name: 'CI Casa de Bolsa' },
    632: { tag: 'BULLTICK C.B.', name: 'Bulltick Casa de Bolsa' },
    633: { tag: 'STERLING', name: 'Sterling Casa de Cambio' },
    634: { tag: 'FINCOMUN', name: 'Fincomún, Servicios Financieros Comunitarios' },
    636: { tag: 'HDI SEGUROS', name: 'HDI Seguros' },
    637: { tag: 'ORDER', name: 'OrderExpress Casa de Cambio' },
    638: { tag: 'NU MEXICO', name: 'Nu México Financiera (Nubank)' },
    640: { tag: 'CB JPMORGAN', name: 'J.P. Morgan Casa de Bolsa' },
    642: { tag: 'REFORMA', name: 'Operadora de Recursos Reforma' },
    646: { tag: 'STP', name: 'Sistema de Transferencias y Pagos STP, SOFOM E.N.R.' },
    647: { tag: 'TELECOMM', name: 'Telecomunicaciones de México' },
    648: { tag: 'EVERCORE', name: 'Evercore Casa de Bolsa' },
    649: { tag: 'SKANDIA', name: 'Skandia Operadora' },
    651: { tag: 'SEGMTY', name: 'Seguros Monterrey New York Life' },
    652: { tag: 'CREDICAPITAL', name: 'Solución Asea, Sociedad Financiera Popular' },
    653: { tag: 'KUSPIT', name: 'Kuspit Casa de Bolsa' },
    655: { tag: 'SOFIEXPRESS', name: 'JP SofiExpress' },
    656: { tag: 'UNAGRA', name: 'UNAGRA, S.F.P.' },
    659: { tag: 'ASP INTEGRA OPC', name: 'Opciones Empresariales Del Noreste' },
    661: { tag: 'ALTERNATIVOS', name: 'Servicios Financieros Alternativos (Klar)' },
    670: { tag: 'LIBERTAD', name: 'Libertad Servicios Financieros' },
    674: { tag: 'AXA', name: 'AXA Seguros' },
    677: { tag: 'CAJA POP MEXICA', name: 'Caja Popular Mexicana' },
    679: { tag: 'FND', name: 'Financiera Nacional De Desarrollo Agropecuario' },
    680: { tag: 'CRISTOBAL COLON', name: 'Caja Popular Cristóbal Colón' },
    683: { tag: 'CAJA TELEFONIST', name: 'Caja de Ahorro de los Telefonistas' },
    684: { tag: 'TRANSFER', name: 'Operadora de Pagos Moviles de Mexico' },
    685: {
      tag: 'FONDO (FIRA)',
      name: 'Fondo de Garantía y Fomento para la Agricultura, Ganadería y Avicultura (FONDO)'
    },
    686: { tag: 'INVERCAP', name: 'Afore InverCap' },
    689: { tag: 'FOMPED', name: 'Fondo Mexicano del Petroleo para la Estabilizacion y el Desarrollo' },
    706: { tag: 'ARCUS', name: 'Arcus Financial Intelligence' },
    710: { tag: 'NVIO', name: 'NVIO Pagos México' },
    722: { tag: 'MERCADO PAGO W', name: 'Mercado Pago W' },
    723: { tag: 'CUENCA', name: 'Cuenca' },
    812: { tag: 'BBVA BANCOMER2', name: 'BBVA Bancomer' },
    814: { tag: 'SANTANDER2', name: 'Banco Santander' },
    821: { tag: 'HSBC2', name: 'HSBC México' },
    846: { tag: 'STP', name: 'Sistema de Transferencias y Pagos STP' },
    901: { tag: 'CLS', name: 'CLS Bank International' },
    902: { tag: 'INDEVAL', name: 'SD. INDEVAL' },
    903: { tag: 'CODI VALIDA', name: 'CoDi Valida' },
    999: { tag: 'N/A', name: 'N/A' }
  },

  cities: <ClabeCity[]>[
    // Sources:
    //    https://en.wikipedia.org/wiki/Template:Mexico_State-Abbreviation_CodesMX
    //    https://es.wikipedia.org/wiki/CLABE#C.C3.B3digo_de_plaza
    [10, 'Aguascalientes', 'MX-AGU'],
    [11, 'Asientos', 'MX-AGU'],
    [12, 'Calvillo', 'MX-AGU'],
    [13, 'Cosio', 'MX-AGU'],
    [14, 'Jesús María', 'MX-AGU'],
    [15, 'Pabellon de Arteaga', 'MX-AGU'],
    [16, 'Rincon de Romos', 'MX-AGU'],
    [17, 'San Fco Romo', 'MX-AGU'],
    [20, 'Mexicali', 'MX-BCN'],
    [21, 'Ciudad Morelos', 'MX-BCN'],
    [22, 'Ensenada', 'MX-BCN'],
    [23, 'Guadalupe Victoria', 'MX-BCN'],
    [24, 'Rodolfo Sanchez T.', 'MX-BCN'],
    [25, 'San Felipe', 'MX-BCN'],
    [26, 'San Quintin', 'MX-BCN'],
    [27, 'Tecate', 'MX-BCN'],
    [27, 'Tijuana'],
    [28, 'La Mesa'],
    [28, 'Playas de Rosarito', 'MX-BCN'],
    [28, 'Tijuana'], // see first occurrence at 27
    [40, 'La Paz', 'MX-BCS'],
    [41, 'Cabo San Lucas', 'MX-BCS'],
    [42, 'Ciudad Constitución', 'MX-BCS'],
    [43, 'Guerrero Negro', 'MX-BCS'],
    [44, 'Loreto', 'MX-BCS'],
    [45, 'San José del Cabo', 'MX-BCS'],
    [46, 'Santa Rosalía', 'MX-BCS'],
    [47, 'Todos Santos', 'MX-BCS'],
    [50, 'Campeche', 'MX-CAM'],
    [51, 'Calkiní', 'MX-CAM'],
    [52, 'Ciudad del Carmen', 'MX-CAM'],
    [53, 'Champotón', 'MX-CAM'],
    [54, 'Escarcega', 'MX-CAM'],
    [55, 'Hopelchen', 'MX-CAM'],
    [56, 'Palizada', 'MX-CAM'],
    [57, 'Seybaplaya', 'MX-CAM'],
    [60, 'Gómez Palacio'],
    [60, 'Torreón', 'MX-COA'],
    [61, 'Abasolo', 'MX-COA'],
    [62, 'Acuña', 'MX-COA'],
    [62, 'Ciudad Acuña'],
    [63, 'Allende', 'MX-COA'],
    [64, 'Francisco I. Madero', 'MX-COA'],
    [65, 'Frontera', 'MX-COA'],
    [66, 'Laguna del Rey', 'MX-COA'],
    [67, 'Matamoros', 'MX-COA'],
    [68, 'Monclova', 'MX-COA'],
    [69, 'Morelos', 'MX-COA'],
    [70, 'Muzquiz', 'MX-COA'],
    [71, 'Nava', 'MX-COA'],
    [72, 'Nueva Rosita'],
    [72, 'Nva Rosita', 'MX-COA'],
    [73, 'Palau', 'MX-COA'],
    [74, 'Parras de la Fuente', 'MX-COA'],
    [75, 'Piedras Negras', 'MX-COA'],
    [76, 'Ramos Arizpe', 'MX-COA'],
    [77, 'Sabinas', 'MX-COA'],
    [78, 'Saltillo', 'MX-COA'],
    [79, 'San Buenaventura', 'MX-COA'],
    [80, 'San Pedro de las Colonias', 'MX-COA'],
    [81, 'Zaragoza', 'MX-COA'],
    [90, 'Colima', 'MX-COL'],
    [91, 'Armeria', 'MX-COL'],
    [92, 'Comala', 'MX-COL'],
    [93, 'Coquimatlan', 'MX-COL'],
    [94, 'Cuauhtemoc', 'MX-COL'],
    [95, 'Manzanillo', 'MX-COL'],
    [96, 'Queseria', 'MX-COL'],
    [97, 'Tecomán', 'MX-COL'],
    [98, 'Villa de Alvarez', 'MX-COL'],
    [100, 'Terán'],
    [100, 'Tuxtla Gutiérrez', 'MX-CHP'],
    [101, 'Acapetahua', 'MX-CHP'],
    [102, 'Angel Albino Corzo', 'MX-CHP'],
    [103, 'Arriaga', 'MX-CHP'],
    [104, 'Bochil', 'MX-CHP'],
    [105, 'Cacahoatan', 'MX-CHP'],
    [106, 'Catazaja', 'MX-CHP'],
    [107, 'Cintalapa', 'MX-CHP'],
    [108, 'Ciudad Hidalgo', 'MX-CHP'],
    [109, 'Comitán de Dominguez', 'MX-CHP'],
    [109, 'Villa Las Rosas'],
    [110, 'Copainala', 'MX-CHP'],
    [111, 'Chiapa de Corso', 'MX-CHP'],
    [112, 'Chicomuselo', 'MX-CHP'],
    [113, 'Frontera Comalapa', 'MX-CHP'],
    [114, 'Huixtla', 'MX-CHP'],
    [115, 'Jiquipilas', 'MX-CHP'],
    [116, 'Juarez', 'MX-CHP'],
    [117, 'La Concordia', 'MX-CHP'],
    [118, 'La Trinitaria', 'MX-CHP'],
    [119, 'Las Margaritas', 'MX-CHP'],
    [120, 'Las Rosas', 'MX-CHP'],
    [121, 'Mapastepec', 'MX-CHP'],
    [122, 'Motozintla', 'MX-CHP'],
    [123, 'Ocosingo', 'MX-CHP'],
    [124, 'Ocozocoautla', 'MX-CHP'],
    [125, 'Palenque', 'MX-CHP'],
    [126, 'Pichucalco', 'MX-CHP'],
    [127, 'Pijijiapan', 'MX-CHP'],
    [128, 'Reforma', 'MX-CHP'],
    [129, 'Salto de Agua', 'MX-CHP'],
    [130, 'San Cristóbal de las Casas', 'MX-CHP'],
    [131, 'Simojovel', 'MX-CHP'],
    [132, 'Socoltenango', 'MX-CHP'],
    [133, 'Tapachula', 'MX-CHP'],
    [134, 'Teopisca', 'MX-CHP'],
    [135, 'Tonala', 'MX-CHP'],
    [136, 'Tuxtla Chico', 'MX-CHP'],
    [137, 'Venustiano Carranza', 'MX-CHP'],
    [138, 'Villa Corzo', 'MX-CHP'],
    [138, 'Villa Flores'],
    [140, 'Yajalón', 'MX-CHP'],
    [150, 'Chihuahua', 'MX-CHH'],
    [150, 'Ciudad Delicias'],
    [151, 'Ahumada', 'MX-CHH'],
    [152, 'Anahuac', 'MX-CHH'],
    [152, 'Ciudad Anáhuac'],
    [153, 'Ascension', 'MX-CHH'],
    [154, 'Buenaventura', 'MX-CHH'],
    [155, 'Camargo', 'MX-CHH'],
    [155, 'Ciudad Camargo'],
    [156, 'Casas Grandes', 'MX-CHH'],
    [157, 'Creel', 'MX-CHH'],
    [158, 'Ciudad Cuauhtémoc'],
    [158, 'Cuauhtemoc', 'MX-CHH'],
    [159, 'Gomez Farias', 'MX-CHH'],
    [160, 'Guachochi', 'MX-CHH'],
    [161, 'Ciudad Guerrero'],
    [161, 'Guerrero', 'MX-CHH'],
    [162, 'Hidalgo del Parral', 'MX-CHH'],
    [162, 'Parral'],
    [163, 'Ciudad Jiménez', 'MX-CHH'],
    [164, 'Ciudad Juárez', 'MX-CHH'],
    [165, 'Ciudad Madera'],
    [165, 'Madera', 'MX-CHH'],
    [166, 'Meoqui', 'MX-CHH'],
    [167, 'El Molino de Namiquipa'],
    [167, 'Namiquipa', 'MX-CHH'],
    [168, 'Nuevo Casas Grandes'],
    [168, 'Nvo Casas Grandes', 'MX-CHH'],
    [169, 'Ojinaga', 'MX-CHH'],
    [170, 'Praxedis G. Guerrero', 'MX-CHH'],
    [171, 'Puerto Palomas', 'MX-CHH'],
    [172, 'Santa Barbara', 'MX-CHH'],
    [173, 'Saucillo', 'MX-CHH'],
    [180, 'Atizapan'],
    [180, 'Chalco'],
    [180, 'Ciudad de México', 'MX-CMX'],
    [180, 'Coacalco'],
    [180, 'Cuautitlán Izcalli'],
    [180, 'Ecatepec'],
    [180, 'Huehuetoca'],
    [180, 'Huixquilucan'],
    [180, 'Ixtapaluca'],
    [180, 'Los Reyes la Paz'],
    [180, 'Naucalpan'],
    [180, 'Nezahualcóyotl'],
    [180, 'Tecamac'],
    [180, 'Teotihuacán'],
    [180, 'Texcoco'],
    [180, 'Tlalnepantla'],
    [190, 'Durango', 'MX-DUR'],
    [191, 'Canatlan', 'MX-DUR'],
    [192, 'Cuencame', 'MX-DUR'],
    [193, 'El Salto', 'MX-DUR'],
    [194, 'Guadalupe Victoria', 'MX-DUR'],
    [195, 'Nazas', 'MX-DUR'],
    [196, 'Nvo Ideal', 'MX-DUR'],
    [197, 'San Juan del Rio', 'MX-DUR'],
    [198, 'Santa Maria del Oro', 'MX-DUR'],
    [199, 'Santiago Papasquiaro', 'MX-DUR'],
    [200, 'Tayoltita', 'MX-DUR'],
    [201, 'Tepehuanes', 'MX-DUR'],
    [202, 'Vicente Guerrero', 'MX-DUR'],
    [210, 'Guanajuato', 'MX-GUA'],
    [211, 'Abasolo', 'MX-GUA'],
    [212, 'Acámbaro', 'MX-GUA'],
    [213, 'Apaseo el Alto', 'MX-GUA'],
    [214, 'Apaseo el Grande', 'MX-GUA'],
    [215, 'Celaya', 'MX-GUA'],
    [216, 'Comonfort', 'MX-GUA'],
    [217, 'Coroneo', 'MX-GUA'],
    [218, 'Cortazar', 'MX-GUA'],
    [219, 'Cuerámaro', 'MX-GUA'],
    [220, 'Dolores Hidalgo', 'MX-GUA'],
    [221, 'Huanimaro', 'MX-GUA'],
    [222, 'Irapuato', 'MX-GUA'],
    [223, 'Jaral del Progreso', 'MX-GUA'],
    [224, 'Jerécuaro', 'MX-GUA'],
    [225, 'León', 'MX-GUA'],
    [226, 'Manuel Doblado', 'MX-GUA'],
    [227, 'Moroleón', 'MX-GUA'],
    [228, 'Ocampo', 'MX-GUA'],
    [229, 'Pénjamo', 'MX-GUA'],
    [230, 'Pueblo Nuevo', 'MX-GUA'],
    [231, 'Purisima del Rincon', 'MX-GUA'],
    [232, 'Romita', 'MX-GUA'],
    [233, 'Salamanca', 'MX-GUA'],
    [234, 'Salvatierra', 'MX-GUA'],
    [235, 'San Diego de la Union', 'MX-GUA'],
    [236, 'San Felipe Progreso', 'MX-GUA'],
    [237, 'Purísima de Bustos'],
    [237, 'San Francisco del Rincoón', 'MX-GUA'],
    [238, 'San José Iturbide', 'MX-GUA'],
    [239, 'San Luis de la Paz', 'MX-GUA'],
    [240, 'San Miguel de Allende', 'MX-GUA'],
    [241, 'Santa Ana Pacueco', 'MX-GUA'],
    [242, 'Santa Cruz de Juventino', 'MX-GUA'],
    [243, 'Santiago Maravatio', 'MX-GUA'],
    [244, 'Silao', 'MX-GUA'],
    [245, 'Tarandacuao', 'MX-GUA'],
    [246, 'Tarimoro', 'MX-GUA'],
    [247, 'Uriangato', 'MX-GUA'],
    [248, 'Valle de Santiago', 'MX-GUA'],
    [249, 'Yuriria', 'MX-GUA'],
    [260, 'Chilpancingo', 'MX-GRO'],
    [261, 'Acapulco de Juarez', 'MX-GRO'],
    [262, 'Apaxtla de Castrejon', 'MX-GRO'],
    [263, 'Arcelia', 'MX-GRO'],
    [264, 'Atoyac de Álvarez', 'MX-GRO'],
    [265, 'Buenavista de Cuellar', 'MX-GRO'],
    [266, 'Ciudad Altamirano', 'MX-GRO'],
    [267, 'Coyuca de Benítez', 'MX-GRO'],
    [268, 'Cruz Grande', 'MX-GRO'],
    [269, 'Chichihualco', 'MX-GRO'],
    [270, 'Chilapa de Alvarez', 'MX-GRO'],
    [271, 'Huitzuco', 'MX-GRO'],
    [272, 'Iguala', 'MX-GRO'],
    [272, 'La Sabana'],
    [273, 'Olinala', 'MX-GRO'],
    [274, 'Cuajinicuilapa'],
    [274, 'Ometepec', 'MX-GRO'],
    [275, 'San Marcos', 'MX-GRO'],
    [276, 'Taxco de Alarcon', 'MX-GRO'],
    [277, 'Petatlan', 'MX-GRO'],
    [278, 'Teloloapan', 'MX-GRO'],
    [279, 'Tierra Colorada', 'MX-GRO'],
    [280, 'Tixtla de Guerrero', 'MX-GRO'],
    [281, 'Tlapa de Comonfort', 'MX-GRO'],
    [282, 'Ixtapa Zihuatanejo', 'MX-GRO'],
    [290, 'Pachuca de Soto', 'MX-HID'],
    [291, 'Actopan', 'MX-HID'],
    [292, 'Apam', 'MX-HID'],
    [293, 'Atotonilco el Grande', 'MX-HID'],
    [294, 'Ciudad Sahagún', 'MX-HID'],
    [294, 'Teocaltiche'],
    [295, 'Cuautepec de Hinojosa', 'MX-HID'],
    [296, 'Huejutla de Reyes', 'MX-HID'],
    [297, 'Huichapan', 'MX-HID'],
    [298, 'Ixmiquilpan', 'MX-HID'],
    [299, 'Jacala', 'MX-HID'],
    [300, 'Metztitlan', 'MX-HID'],
    [301, 'Mixquiahuala', 'MX-HID'],
    [302, 'Molango', 'MX-HID'],
    [303, 'Progreso de Obregón', 'MX-HID'],
    [304, 'Tasquillo', 'MX-HID'],
    [305, 'Tepeapulco', 'MX-HID'],
    [306, 'Tepeji del Rio', 'MX-HID'],
    [307, 'Tezontepec', 'MX-HID'],
    [308, 'Tizayuca', 'MX-HID'],
    [309, 'Tlahuelilpan', 'MX-HID'],
    [310, 'Tlaxcoapan', 'MX-HID'],
    [311, 'Tula de Allende', 'MX-HID'],
    [312, 'Tulancingo', 'MX-HID'],
    [313, 'Zacualtipán', 'MX-HID'],
    [314, 'Zimapán', 'MX-HID'],
    [320, 'El Salto', 'MX-JAL'],
    [320, 'Guadalajara'],
    [320, 'San Pedro Tlaquepaque'],
    [320, 'Tlajomulco'],
    [320, 'Tonala'],
    [320, 'Zapopan'],
    [321, 'Acatic', 'MX-JAL'],
    [322, 'Acatlan de Juarez', 'MX-JAL'],
    [323, 'Ahualulco de Mercado', 'MX-JAL'],
    [324, 'Ajijic', 'MX-JAL'],
    [325, 'Amatitan', 'MX-JAL'],
    [326, 'Ameca', 'MX-JAL'],
    [327, 'Arandas', 'MX-JAL'],
    [328, 'Arenal', 'MX-JAL'],
    [329, 'Atenguillo', 'MX-JAL'],
    [330, 'Atotonilco el Alto', 'MX-JAL'],
    [331, 'Atequiza'],
    [331, 'Atotonilquillo', 'MX-JAL'],
    [332, 'Atoyac', 'MX-JAL'],
    [333, 'Autlán de Navarro', 'MX-JAL'],
    [334, 'Ayotlan', 'MX-JAL'],
    [334, 'Azteca'],
    [335, 'Ayutla', 'MX-JAL'],
    [336, 'Bajio de San Jose', 'MX-JAL'],
    [337, 'Belen del Refugio', 'MX-JAL'],
    [338, 'Cañadas de Obregon', 'MX-JAL'],
    [339, 'Capilla de Guadalupe', 'MX-JAL'],
    [340, 'Casimiro Castillo', 'MX-JAL'],
    [341, 'Cihuatlán', 'MX-JAL'],
    [342, 'Ciudad Guzmán', 'MX-JAL'],
    [343, 'Cocula', 'MX-JAL'],
    [344, 'Ixtlixochitl', 'MX-MEX'],
    [345, 'Cuquio', 'MX-JAL'],
    [346, 'Chapala', 'MX-JAL'],
    [347, 'Degollado', 'MX-JAL'],
    [348, 'El Grullo', 'MX-JAL'],
    [349, 'Encarnacion de Diaz', 'MX-JAL'],
    [350, 'Estipac', 'MX-JAL'],
    [351, 'Guachinango', 'MX-JAL'],
    [352, 'Huejucar', 'MX-JAL'],
    [353, 'Huejuquilla el Alto', 'MX-JAL'],
    [354, 'Ixtlahuacan de los M.', 'MX-JAL'],
    [355, 'Ixtlahuacán del Río', 'MX-JAL'],
    [356, 'Jalostotitlán', 'MX-JAL'],
    [357, 'Jamay', 'MX-JAL'],
    [358, 'Jesus Maria', 'MX-JAL'],
    [359, 'Jocotepec', 'MX-JAL'],
    [360, 'Juchitlan', 'MX-JAL'],
    [361, 'La Barca', 'MX-JAL'],
    [362, 'Lagos de Moreno', 'MX-JAL'],
    [363, 'Magdalena', 'MX-JAL'],
    [364, 'Manzanilla de la Paz', 'MX-JAL'],
    [365, 'Mascota', 'MX-JAL'],
    [366, 'Mazamitla', 'MX-JAL'],
    [367, 'Mexticacan', 'MX-JAL'],
    [368, 'Mezquitic', 'MX-JAL'],
    [369, 'Mixtlan', 'MX-JAL'],
    [370, 'Ocotlán', 'MX-JAL'],
    [371, 'Ojuelos de Jalisco', 'MX-JAL'],
    [372, 'Pegueros', 'MX-JAL'],
    [373, 'Pihuamo', 'MX-JAL'],
    [374, 'Poncitlan', 'MX-JAL'],
    [375, 'Las Juntas'],
    [375, 'Nuevo Vallarta'],
    [375, 'Pitillal'],
    [375, 'Puerto Vallarta', 'MX-JAL'],
    [376, 'Purificacion', 'MX-JAL'],
    [377, 'Quitupan', 'MX-JAL'],
    [378, 'San Ignacio Cerro G.', 'MX-JAL'],
    [379, 'San Jose Casas Caidas', 'MX-JAL'],
    [380, 'San Jose de Gracia', 'MX-JAL'],
    [381, 'San Juan de los Lagos', 'MX-JAL'],
    [382, 'San Julian', 'MX-JAL'],
    [383, 'San Martin Hidalgo', 'MX-JAL'],
    [384, 'San Miguel el Alto', 'MX-JAL'],
    [385, 'San Patricio Melaque', 'MX-JAL'],
    [386, 'Sayula', 'MX-JAL'],
    [387, 'Tala', 'MX-JAL'],
    [388, 'Talpa de Allende', 'MX-JAL'],
    [389, 'Tamazula de Gordiano', 'MX-JAL'],
    [390, 'Tapalpa', 'MX-JAL'],
    [391, 'Tecalitlán', 'MX-JAL'],
    [392, 'Tecolotlan', 'MX-JAL'],
    [393, 'Tenamaxtlan', 'MX-JAL'],
    [394, 'Teocaltiche', 'MX-JAL'],
    [395, 'Teocuitatlan de Corona', 'MX-JAL'],
    [396, 'Tepatitlán de Morelos', 'MX-JAL'],
    [397, 'Tequila', 'MX-JAL'],
    [398, 'Tizapan el Alto', 'MX-JAL'],
    [399, 'Toliman', 'MX-JAL'],
    [400, 'Tomatlan', 'MX-JAL'],
    [401, 'Tonaya', 'MX-JAL'],
    [402, 'Totatiche', 'MX-JAL'],
    [403, 'Tototlán', 'MX-JAL'],
    [404, 'Túxpam'],
    [404, 'Tuxpan', 'MX-JAL'],
    [405, 'Union de San Antonio', 'MX-JAL'],
    [406, 'Union de Tula', 'MX-JAL'],
    [407, 'Valle de Guadalupe', 'MX-JAL'],
    [408, 'Valle de Juarez', 'MX-JAL'],
    [409, 'Villa Corona', 'MX-JAL'],
    [410, 'Villa Guerrero', 'MX-JAL'],
    [411, 'Villa Hidalgo', 'MX-JAL'],
    [412, 'Vista Hermosa', 'MX-JAL'],
    [413, 'Zacoalco de Torres', 'MX-JAL'],
    [414, 'Zapotiltic', 'MX-JAL'],
    [415, 'Zapotlan del Rey', 'MX-JAL'],
    [416, 'Zapotlanejo', 'MX-JAL'],
    [420, 'Toluca', 'MX-MEX'],
    [421, 'Acambay', 'MX-MEX'],
    [422, 'Almoloya de Juárez', 'MX-MEX'],
    [423, 'Amatepec', 'MX-MEX'],
    [424, 'Amecameca', 'MX-MEX'],
    [425, 'Apaxco', 'MX-MEX'],
    [426, 'Atlacomulco', 'MX-MEX'],
    [427, 'Capulhuac', 'MX-MEX'],
    [428, 'Coatepec de Harinas', 'MX-MEX'],
    [429, 'Colorines', 'MX-MEX'],
    [430, 'Chicoloapan', 'MX-MEX'],
    [431, 'Chiconcuac', 'MX-MEX'],
    [432, 'El Oro', 'MX-MEX'],
    [433, 'Ixtapan de la Sal', 'MX-MEX'],
    [434, 'Ixtlahuaca', 'MX-MEX'],
    [435, 'Jilotepec', 'MX-MEX'],
    [436, 'Jocotitlan', 'MX-MEX'],
    [437, 'Chimalhuacan', 'MX-MEX'],
    [437, 'La Paz (Reyes Acaq.)', 'MX-MEX'],
    [438, 'Lerma', 'MX-MEX'],
    [439, 'Luvianos', 'MX-MEX'],
    [440, 'Malinalco', 'MX-MEX'],
    [441, 'Metepec', 'MX-MEX'],
    [442, 'Ocoyoacac', 'MX-MEX'],
    [443, 'Otumba', 'MX-MEX'],
    [444, 'San Felipe Progreso', 'MX-MEX'],
    [445, 'San Mateo Atenco', 'MX-MEX'],
    [446, 'Tejupilco', 'MX-MEX'],
    [447, 'Temascalcingo', 'MX-MEX'],
    [448, 'Temascaltepec', 'MX-MEX'],
    [449, 'Temoaya', 'MX-MEX'],
    [450, 'Tenancingo', 'MX-MEX'],
    [451, 'Tenago del Valle'],
    [451, 'Tenango de Arista', 'MX-MEX'],
    [452, 'Texcaltitlan', 'MX-MEX'],
    [453, 'Santiago Tiangistenco'],
    [453, 'Tianguistenco', 'MX-MEX'],
    [454, 'Tonatico', 'MX-MEX'],
    [455, 'Tultepec', 'MX-MEX'],
    [456, 'Tultitlán', 'MX-MEX'],
    [457, 'Valle de Bravo', 'MX-MEX'],
    [458, 'Villa del Carbon', 'MX-MEX'],
    [459, 'Villa Guerrero', 'MX-MEX'],
    [460, 'Villa Nicolás Romero', 'MX-MEX'],
    [461, 'Villa Victoria', 'MX-MEX'],
    [462, 'Xonacatlan', 'MX-MEX'],
    [463, 'Zumpango', 'MX-MEX'],
    [470, 'Morelia', 'MX-MIC'],
    [471, 'Acuitzio del Canje', 'MX-MIC'],
    [472, 'Aguililla', 'MX-MIC'],
    [473, 'Alvaro Obregon', 'MX-MIC'],
    [474, 'Angamacutiro', 'MX-MIC'],
    [475, 'Angangueo', 'MX-MIC'],
    [476, 'Apatzingán', 'MX-MIC'],
    [477, 'Ario de Rosales', 'MX-MIC'],
    [478, 'Arteaga', 'MX-MIC'],
    [479, 'Buenavista Tomatlan', 'MX-MIC'],
    [480, 'Ciudad Hidalgo', 'MX-MIC'],
    [481, 'Coalcoman de Vazquez', 'MX-MIC'],
    [482, 'Contepec', 'MX-MIC'],
    [483, 'Cotija de la Paz', 'MX-MIC'],
    [484, 'Cuitzeo', 'MX-MIC'],
    [485, 'Chavinda', 'MX-MIC'],
    [486, 'Cheran', 'MX-MIC'],
    [487, 'Chilchota', 'MX-MIC'],
    [488, 'Churintzio', 'MX-MIC'],
    [489, 'Epitacio Huerta', 'MX-MIC'],
    [490, 'Gabriel Zamora', 'MX-MIC'],
    [491, 'Huandacareo', 'MX-MIC'],
    [492, 'Huetamo', 'MX-MIC'],
    [493, 'Jacona de Plancarte', 'MX-MIC'],
    [494, 'Jiquilpan de Juarez', 'MX-MIC'],
    [495, 'La Huacana', 'MX-MIC'],
    [496, 'La Piedad de Cabadas', 'MX-MIC'],
    [497, 'Lázaro Cárdenas', 'MX-MIC'],
    [498, 'Los Reyes de Salgado', 'MX-MIC'],
    [499, 'Maravatío', 'MX-MIC'],
    [500, 'San Jose de Gracia', 'MX-MIC'],
    [501, 'Nueva Italia'],
    [501, 'Nva Italia de Ruiz', 'MX-MIC'],
    [502, 'Pajacuaran', 'MX-MIC'],
    [503, 'Panindicuaro', 'MX-MIC'],
    [504, 'Paracho', 'MX-MIC'],
    [505, 'Pastor Ortiz', 'MX-MIC'],
    [506, 'Pátzcuaro', 'MX-MIC'],
    [507, 'Periban', 'MX-MIC'],
    [508, 'Purépero de Echaiz', 'MX-MIC'],
    [509, 'Puruandiro', 'MX-MIC'],
    [510, 'Querendaro', 'MX-MIC'],
    [511, 'Quiroga', 'MX-MIC'],
    [512, 'Sahuayo de Morelos', 'MX-MIC'],
    [513, 'Santa Ana Maya', 'MX-MIC'],
    [514, 'Santiago Tangamandapio', 'MX-MIC'],
    [515, 'Tacámbaro Codallos', 'MX-MIC'],
    [516, 'Tancitaro', 'MX-MIC'],
    [517, 'Tangancícuaro Arista', 'MX-MIC'],
    [518, 'Tanhuato de Guerrero', 'MX-MIC'],
    [519, 'Tapalcatepec', 'MX-MIC'],
    [520, 'Taretan', 'MX-MIC'],
    [521, 'Tinguindin', 'MX-MIC'],
    [522, 'Tlalpujahua', 'MX-MIC'],
    [523, 'Tlazazalca', 'MX-MIC'],
    [524, 'Tocumbo', 'MX-MIC'],
    [525, 'Tuxpan', 'MX-MIC'],
    [526, 'Tuzantla', 'MX-MIC'],
    [527, 'Ucareo', 'MX-MIC'],
    [528, 'Uruapan', 'MX-MIC'],
    [529, 'Villa Jimenez', 'MX-MIC'],
    [530, 'Villamar', 'MX-MIC'],
    [531, 'Villa Morelos', 'MX-MIC'],
    [532, 'Vista Hermosa Negrete', 'MX-MIC'],
    [533, 'Yurécuaro', 'MX-MIC'],
    [534, 'Zacapu', 'MX-MIC'],
    [535, 'Zamora', 'MX-MIC'],
    [536, 'Zinapécuaro', 'MX-MIC'],
    [537, 'Zitácuaro', 'MX-MIC'],
    [540, 'Cuernavaca', 'MX-MOR'],
    [541, 'Axochiapan', 'MX-MOR'],
    [542, 'Cuautla', 'MX-MOR'],
    [542, 'Oaxtepec, Morelos'],
    [543, 'Jiutepec', 'MX-MOR'],
    [544, 'Jojutla', 'MX-MOR'],
    [545, 'Puente de Ixtla', 'MX-MOR'],
    [546, 'Temixco', 'MX-MOR'],
    [547, 'Tepoztlan', 'MX-MOR'],
    [548, 'Tetecala', 'MX-MOR'],
    [549, 'Yautepec', 'MX-MOR'],
    [550, 'Yautepec', 'MX-MOR'], // see first occurrence at 549
    [551, 'Yecapixtla', 'MX-MOR'],
    [552, 'Zacatepec', 'MX-MOR'],
    [560, 'Tepic', 'MX-NAY'],
    [561, 'Acaponeta', 'MX-NAY'],
    [562, 'Ahuacatlán', 'MX-NAY'],
    [563, 'Amatlan de Cañas', 'MX-NAY'],
    [564, 'Compostela', 'MX-NAY'],
    [565, 'Huajicori', 'MX-NAY'],
    [566, 'Ixtlán del Río', 'MX-NAY'],
    [567, 'La Penita de Jaltemba', 'MX-NAY'],
    [568, 'Las Varas', 'MX-NAY'],
    [569, 'Rosamorada', 'MX-NAY'],
    [570, 'Ruiz', 'MX-NAY'],
    [571, 'San Blas', 'MX-NAY'],
    [572, 'Santa Maria del Oro', 'MX-NAY'],
    [573, 'Santiago Ixcuintla', 'MX-NAY'],
    [574, 'Tecuala', 'MX-NAY'],
    [575, 'Túxpam', 'MX-NAY'],
    [576, 'Villa Hidalgo', 'MX-NAY'],
    [577, 'Xalisco', 'MX-NAY'],
    [580, 'Apodaca', 'MX-NLE'],
    [580, 'Cadereyta'],
    [580, 'Ciudad Guadalupe'],
    [580, 'General Escobedo'],
    [580, 'Monterrey'],
    [580, 'San Nicolás de los Garza'],
    [580, 'San Pedro Garza García'],
    [580, 'Santa Catarina'],
    [581, 'Abasolo', 'MX-NLE'],
    [582, 'Agualeguas', 'MX-NLE'],
    [583, 'Allende', 'MX-NLE'],
    [584, 'Anahuac', 'MX-NLE'],
    [585, 'Bustamante', 'MX-NLE'],
    [586, 'Cerralvo', 'MX-NLE'],
    [587, 'Cienega de Flores', 'MX-NLE'],
    [588, 'China', 'MX-NLE'],
    [589, 'Galeana', 'MX-NLE'],
    [590, 'General Bravo', 'MX-NLE'],
    [591, 'General Teran', 'MX-NLE'],
    [592, 'General Zuazua', 'MX-NLE'],
    [593, 'Hidalgo', 'MX-NLE'],
    [594, 'Lampazos de Naranjo', 'MX-NLE'],
    [595, 'Linares', 'MX-NLE'],
    [596, 'Los Ramones', 'MX-NLE'],
    [597, 'Montemorelos', 'MX-NLE'],
    [598, 'Pesqueria', 'MX-NLE'],
    [599, 'Sabinas Hidalgo', 'MX-NLE'],
    [600, 'Salinas Victoria', 'MX-NLE'],
    [601, 'El Cercado'],
    [601, 'Santiago', 'MX-NLE'],
    [601, 'Villa de Santiago'],
    [610, 'Oaxaca', 'MX-OAX'],
    [611, 'Asuncion Nochixtlan', 'MX-OAX'],
    [612, 'Guelatao', 'MX-OAX'],
    [613, 'Heroica Cd de Tlaxiaco', 'MX-OAX'],
    [613, 'Tlaxiaco'],
    [614, 'Huajuapan de León', 'MX-OAX'],
    [615, 'Huautla', 'MX-OAX'],
    [616, 'Ixtepec', 'MX-OAX'],
    [617, 'Juchitán de Zaragoza', 'MX-OAX'],
    [618, 'La Reforma', 'MX-OAX'],
    [619, 'Loma Bonita', 'MX-OAX'],
    [620, 'Matías Romero', 'MX-OAX'],
    [621, 'Miahuatlán', 'MX-OAX'],
    [622, 'Ocotlán', 'MX-OAX'],
    [623, 'Pinotepa', 'MX-OAX'],
    [624, 'Puerto Escondido', 'MX-OAX'],
    [625, 'Putla', 'MX-OAX'],
    [626, 'Salina Cruz', 'MX-OAX'],
    [627, 'Lagunas'],
    [627, 'San Andres Lagunas', 'MX-OAX'],
    [628, 'San Juan Bautista T.', 'MX-OAX'],
    [628, 'Tuxtepec'],
    [629, 'San Pedro Mixtepec', 'MX-OAX'],
    [630, 'Pochutla'],
    [630, 'San Pedro Pochutla', 'MX-OAX'],
    [631, 'San Pedro Tapanatepec', 'MX-OAX'],
    [632, 'Santa Lucía del Camino', 'MX-OAX'],
    [633, 'Santa Maria del Tule', 'MX-OAX'],
    [634, 'Bahías de Huatulco'],
    [634, 'Santa Maria Huatulco', 'MX-OAX'],
    [635, 'Santiago Juxtlahuaca', 'MX-OAX'],
    [636, 'Pinotepa Nacional'],
    [636, 'Santiago Pinotepa Nal', 'MX-OAX'],
    [637, 'Sto Domingo Tehuantepec', 'MX-OAX'],
    [637, 'Tehuantepec'],
    [638, 'Tlacolula D Matamoros', 'MX-OAX'],
    [639, 'Villa de Tamazulapam', 'MX-OAX'],
    [640, 'Zimatlán', 'MX-OAX'],
    [650, 'Cholula'],
    [650, 'Cuautlancingo', 'MX-PUE'],
    [650, 'La Resurrección'],
    [650, 'Puebla'],
    [650, 'San Baltazar Campeche'],
    [651, 'Acatlan', 'MX-PUE'],
    [652, 'Acatzingo', 'MX-PUE'],
    [653, 'Amozoc', 'MX-PUE'],
    [654, 'Atlixco', 'MX-PUE'],
    [655, 'Ciudad Serdan', 'MX-PUE'],
    [655, 'Los Heroes de Puebla', 'MX-PUE'],
    [656, 'Cuetzalan', 'MX-PUE'],
    [657, 'Chiautla', 'MX-PUE'],
    [658, 'Chignahuapan', 'MX-PUE'],
    [659, 'Huauchinango', 'MX-PUE'],
    [660, 'Huejotzingo', 'MX-PUE'],
    [661, 'Hueytamalco', 'MX-PUE'],
    [662, 'Izúcar de Matamoros', 'MX-PUE'],
    [663, 'Libres', 'MX-PUE'],
    [664, 'Nealtican', 'MX-PUE'],
    [665, 'Nvo Necaxa', 'MX-PUE'],
    [666, 'Rafael Lara Grajales', 'MX-PUE'],
    [667, 'San Martín Texmelucan', 'MX-PUE'],
    [668, 'San Felipe Hueyotlipan'],
    [668, 'Sto Tomas Hueyotlipan', 'MX-PUE'],
    [669, 'Tecamachalco', 'MX-PUE'],
    [670, 'Tehuacán', 'MX-PUE'],
    [671, 'San Lorenzo'],
    [671, 'Tepeaca', 'MX-PUE'],
    [672, 'Teziutlán', 'MX-PUE'],
    [673, 'Tlatlauquitepec', 'MX-PUE'],
    [674, 'Xicotepec de Juárez', 'MX-PUE'],
    [675, 'Zacapoaxtla', 'MX-PUE'],
    [676, 'Zacatlán', 'MX-PUE'],
    [680, 'El Pueblito Correg.', 'MX-QUE'],
    [680, 'Pedro Escobedo'],
    [680, 'Querétaro'],
    [680, 'Villa Corregidora'],
    [681, 'Amealco', 'MX-QUE'],
    [682, 'Cadereyta', 'MX-QUE'],
    [683, 'Ezequiel Montes', 'MX-QUE'],
    [684, 'Jalpan', 'MX-QUE'],
    [685, 'San Juan del Río', 'MX-QUE'],
    [686, 'Tequisquiapan', 'MX-QUE'],
    [690, 'Chetumal', 'MX-ROO'],
    [691, 'Cancún', 'MX-ROO'],
    [691, 'Col. Puerto Juárez'],
    [692, 'Cozumel', 'MX-ROO'],
    [693, 'Isla Mujeres', 'MX-ROO'],
    [694, 'Playa del Carmen', 'MX-ROO'],
    [700, 'San Luis Potosí', 'MX-SLP'],
    [701, 'Axtla de Terrazas', 'MX-SLP'],
    [702, 'Cardenas', 'MX-SLP'],
    [703, 'Cerritos', 'MX-SLP'],
    [704, 'Ciudad del Maiz', 'MX-SLP'],
    [705, 'Ciudad Valles', 'MX-SLP'],
    [706, 'Charcas', 'MX-SLP'],
    [707, 'Ebano', 'MX-SLP'],
    [708, 'Guadalcazar', 'MX-SLP'],
    [709, 'Matehuala', 'MX-SLP'],
    [710, 'Rayon', 'MX-SLP'],
    [711, 'Río Verde', 'MX-SLP'],
    [712, 'Salinas de Hidalgo', 'MX-SLP'],
    [713, 'Santa Maria del Rio', 'MX-SLP'],
    [714, 'Tamasopo', 'MX-SLP'],
    [715, 'Tamazunchale', 'MX-SLP'],
    [716, 'Tamuín', 'MX-SLP'],
    [717, 'Tancanhuitz de Santos', 'MX-SLP'],
    [718, 'Tanquian', 'MX-SLP'],
    [719, 'Tierra Nueva', 'MX-SLP'],
    [720, 'Venado', 'MX-SLP'],
    [721, 'Xilitla', 'MX-SLP'],
    [722, 'Villa de Arista', 'MX-SLP'],
    [730, 'Culiacán', 'MX-SIN'],
    [731, 'Ahome', 'MX-SIN'],
    [732, 'Angostura', 'MX-SIN'],
    [733, 'Badiraguato', 'MX-SIN'],
    [734, 'Bamoa', 'MX-SIN'],
    [735, 'Concordia', 'MX-SIN'],
    [736, 'Cosala', 'MX-SIN'],
    [737, 'Choix', 'MX-SIN'],
    [738, 'El Fuerte', 'MX-SIN'],
    [739, 'Escuinapa de Hidalgo', 'MX-SIN'],
    [740, 'Guamúchil', 'MX-SIN'],
    [741, 'Guasave', 'MX-SIN'],
    [742, 'La Cruz de Elota', 'MX-SIN'],
    [743, 'Los Mochis', 'MX-SIN'],
    [743, 'Topolobampo'],
    [744, 'Mazatlán', 'MX-SIN'],
    [745, 'Mocorito', 'MX-SIN'],
    [746, 'Navolato', 'MX-SIN'],
    [747, 'Quila', 'MX-SIN'],
    [748, 'Rosario', 'MX-SIN'],
    [749, 'San Blas', 'MX-SIN'],
    [750, 'San Ignacio', 'MX-SIN'],
    [760, 'Hermosillo', 'MX-SON'],
    [761, 'Agua Prieta', 'MX-SON'],
    [762, 'Alamos', 'MX-SON'],
    [763, 'Altar', 'MX-SON'],
    [764, 'Baviacora', 'MX-SON'],
    [765, 'Caborca', 'MX-SON'],
    [766, 'Cananea', 'MX-SON'],
    [767, 'Ciudad Obregón', 'MX-SON'],
    [767, 'Esperanza'],
    [768, 'Cumpas', 'MX-SON'],
    [769, 'Empalme', 'MX-SON'],
    [770, 'Guaymas', 'MX-SON'],
    [770, 'San Carlos'],
    [771, 'Huatabampo', 'MX-SON'],
    [772, 'Luis B. Sanchez', 'MX-SON'],
    [773, 'Magdalena de Kino', 'MX-SON'],
    [774, 'Miguel Aleman', 'MX-SON'],
    [775, 'Moctezuma', 'MX-SON'],
    [776, 'Nacozari de García', 'MX-SON'],
    [777, 'Navojoa', 'MX-SON'],
    [778, 'Nogales', 'MX-SON'],
    [779, 'Puerto Peñasco', 'MX-SON'],
    [780, 'San Luis Río Colorado', 'MX-SON'],
    [781, 'Santa Ana', 'MX-SON'],
    [782, 'Ures', 'MX-SON'],
    [783, 'Villa Juarez (Irrigacion)', 'MX-SON'],
    [790, 'Tamulte'],
    [790, 'Villa Hermosa', 'MX-TAB'],
    [791, 'Balancan', 'MX-TAB'],
    [792, 'Cárdenas'],
    [792, 'Cardenas', 'MX-TAB'],
    [793, 'Ciudad Pemex', 'MX-TAB'],
    [794, 'Comalcalco', 'MX-TAB'],
    [795, 'Cunduacan', 'MX-TAB'],
    [796, 'Emiliano Zapata', 'MX-TAB'],
    [797, 'Frontera', 'MX-TAB'],
    [798, 'Huimanguillo', 'MX-TAB'],
    [799, 'Jalapa', 'MX-TAB'],
    [800, 'Jalpa de Méndez', 'MX-TAB'],
    [801, 'Jonuta', 'MX-TAB'],
    [802, 'Macuspana', 'MX-TAB'],
    [803, 'Nacajuca', 'MX-TAB'],
    [804, 'Paraíso', 'MX-TAB'],
    [805, 'Tacotalpa', 'MX-TAB'],
    [806, 'Teapa', 'MX-TAB'],
    [807, 'Tenosique', 'MX-TAB'],
    [810, 'Ciudad Victoria', 'MX-TAM'],
    [811, 'Altamira', 'MX-TAM'],
    [812, 'Camargo', 'MX-TAM'],
    [813, 'Ciudad Madero', 'MX-TAM'],
    [813, 'Tampico'],
    [814, 'Ciudad Mante', 'MX-TAM'],
    [815, 'Ciudad Miguel Aleman', 'MX-TAM'],
    [816, 'Gonzalez', 'MX-TAM'],
    [817, 'Gustavo Diaz Ordaz', 'MX-TAM'],
    [818, 'Matamoros', 'MX-TAM'],
    [819, 'Mier', 'MX-TAM'],
    [820, 'Nva Ciudad Guerrero', 'MX-TAM'],
    [821, 'Colombia'],
    [821, 'Nuevo Laredo', 'MX-TAM'],
    [822, 'Hidalgo', 'MX-TAM'],
    [822, 'Reynosa'],
    [823, 'Río Bravo', 'MX-TAM'],
    [824, 'San Fernando', 'MX-TAM'],
    [825, 'Soto la Marina', 'MX-TAM'],
    [826, 'Valle Hermoso', 'MX-TAM'],
    [827, 'Xicotencatl', 'MX-TAM'],
    [830, 'Tlaxcala', 'MX-TLA'],
    [831, 'Amaxac de Guerrero', 'MX-TLA'],
    [832, 'Apizaco', 'MX-TLA'],
    [833, 'Calpulalpan', 'MX-TLA'],
    [834, 'Chiautempan', 'MX-TLA'],
    [834, 'Santa Ana Chiautempan'],
    [835, 'Huamantla', 'MX-TLA'],
    [836, 'Papalotla', 'MX-TLA'],
    [837, 'Santa Maria Nativitas', 'MX-TLA'],
    [838, 'Tlaxco', 'MX-TLA'],
    [839, 'Zacatelco', 'MX-TLA'],
    [840, 'Jalapa'],
    [840, 'Xalapa', 'MX-VER'],
    [841, 'Acayucan', 'MX-VER'],
    [842, 'Adalberto Tejeda', 'MX-VER'],
    [843, 'Agua Dulce', 'MX-VER'],
    [844, 'Agua Dulce (Papantla)', 'MX-VER'],
    [845, 'Álamo', 'MX-VER'],
    [846, 'Altotonga', 'MX-VER'],
    [847, 'Alvarado', 'MX-VER'],
    [848, 'Banderilla', 'MX-VER'],
    [849, 'Boca del Río', 'MX-VER'],
    [850, 'Catemaco', 'MX-VER'],
    [851, 'Cerro Azul', 'MX-VER'],
    [852, 'Ciudad Mendoza', 'MX-VER'],
    [853, 'Coatepec', 'MX-VER'],
    [854, 'Coatzacoalcos', 'MX-VER'],
    [855, 'Córdoba', 'MX-VER'],
    [856, 'Cosamaloapan', 'MX-VER'],
    [858, 'Coscomatepec', 'MX-VER'],
    [859, 'Cosoleacaque', 'MX-VER'],
    [860, 'Cuitláhuac', 'MX-VER'],
    [861, 'Chicontepec', 'MX-VER'],
    [862, 'El Naranjal', 'MX-VER'],
    [863, 'Fortín de las Flores', 'MX-VER'],
    [864, 'Gutiérrez Zamora', 'MX-VER'],
    [865, 'Huatusco', 'MX-VER'],
    [866, 'Hueyapan de Ocampo', 'MX-VER'],
    [867, 'Isla', 'MX-VER'],
    [868, 'Ixtaczoquitlán', 'MX-VER'],
    [869, 'Jáltipan de Morelos', 'MX-VER'],
    [870, 'Jesus Carranza', 'MX-VER'],
    [871, 'Juan Rodríguez Clara', 'MX-VER'],
    [872, 'La Antigua', 'MX-VER'],
    [872, 'Villa José Cardel'],
    [873, 'Las Choapas', 'MX-VER'],
    [874, 'Lerdo de Tejada', 'MX-VER'],
    [875, 'Los Naranjos', 'MX-VER'],
    [875, 'Naranjos'],
    [876, 'Martínez de la Torre', 'MX-VER'],
    [877, 'Minatitlán', 'MX-VER'],
    [878, 'Misantla', 'MX-VER'],
    [879, 'Nanchital', 'MX-VER'],
    [880, 'Naolinco', 'MX-VER'],
    [881, 'Naranjos', 'MX-VER'],
    [882, 'Orizaba', 'MX-VER'],
    [883, 'Ozuluama', 'MX-VER'],
    [884, 'Panuco', 'MX-VER'],
    [885, 'Papantla', 'MX-VER'],
    [886, 'Perote', 'MX-VER'],
    [887, 'Playa Vicente', 'MX-VER'],
    [888, 'Poza Rica', 'MX-VER'],
    [889, 'Río Blanco', 'MX-VER'],
    [890, 'San Andrés Tuxtla', 'MX-VER'],
    [891, 'San Rafael', 'MX-VER'],
    [892, 'Soledad de Doblado', 'MX-VER'],
    [893, 'Tamiahua', 'MX-VER'],
    [894, 'Platón Sánchez'],
    [894, 'Tantoyuca', 'MX-VER'],
    [895, 'Tempoal de Sanchez', 'MX-VER'],
    [896, 'Teocelo', 'MX-VER'],
    [897, 'Tezonapa', 'MX-VER'],
    [898, 'Tierra Blanca', 'MX-VER'],
    [899, 'Tihuatlan', 'MX-VER'],
    [900, 'Tlacotalpan', 'MX-VER'],
    [901, 'Tlapacoyan', 'MX-VER'],
    [902, 'Tres Valles', 'MX-VER'],
    [903, 'Túxpam de Rodríguez Cano'],
    [903, 'Tuxpan', 'MX-VER'],
    [904, 'Vega de Alatorre', 'MX-VER'],
    [905, 'Ciudad Industrial Framboyan'],
    [905, 'Veracruz', 'MX-VER'],
    [906, 'Xico', 'MX-VER'],
    [910, 'Mérida', 'MX-YUC'],
    [911, 'Izamal', 'MX-YUC'],
    [912, 'Maxcanu', 'MX-YUC'],
    [913, 'Motul', 'MX-YUC'],
    [914, 'Oxkutzcab', 'MX-YUC'],
    [915, 'Progreso'],
    [915, 'Puerto Progreso', 'MX-YUC'],
    [916, 'Tekax', 'MX-YUC'],
    [917, 'Ticul', 'MX-YUC'],
    [918, 'Tizimín', 'MX-YUC'],
    [919, 'Uman', 'MX-YUC'],
    [920, 'Valladolid', 'MX-YUC'],
    [930, 'Zacatecas', 'MX-ZAC'],
    [931, 'Apozol', 'MX-ZAC'],
    [932, 'Concepcion del Oro', 'MX-ZAC'],
    [933, 'Fresnillo', 'MX-ZAC'],
    [934, 'Guadalupe', 'MX-ZAC'],
    [935, 'Jalpa', 'MX-ZAC'],
    [936, 'Jerez de Garcia Salinas', 'MX-ZAC'],
    [937, 'Juan Aldama', 'MX-ZAC'],
    [938, 'Juchipila', 'MX-ZAC'],
    [939, 'Loreto', 'MX-ZAC'],
    [940, 'Luis Moya', 'MX-ZAC'],
    [941, 'Miguel Auza', 'MX-ZAC'],
    [942, 'Monte Escobedo', 'MX-ZAC'],
    [943, 'Morelos', 'MX-ZAC'],
    [944, 'Moyahua de Estrada', 'MX-ZAC'],
    [945, 'Nieves', 'MX-ZAC'],
    [946, 'Nochistlán de Mejia', 'MX-ZAC'],
    [947, 'Ojocaliente', 'MX-ZAC'],
    [948, 'Pinos', 'MX-ZAC'],
    [949, 'Rio Grande', 'MX-ZAC'],
    [950, 'Sain Alto', 'MX-ZAC'],
    [951, 'San Pedro Apulco', 'MX-ZAC'],
    [952, 'Sombrerete', 'MX-ZAC'],
    [953, 'Tabasco', 'MX-ZAC'],
    [954, 'Tepechitlan', 'MX-ZAC'],
    [955, 'Tepetongo', 'MX-ZAC'],
    [956, 'Teul de Glez. Ortega', 'MX-ZAC'],
    [957, 'Cosautlan', 'MX-VER'],
    [958, 'Valparaíso', 'MX-ZAC'],
    [959, 'Valla de Cos', 'MX-ZAC'],
    [960, 'Victor Rosales', 'MX-ZAC'],
    [961, 'Villa Gonzalez Ortega', 'MX-ZAC'],
    [962, 'Villanueva', 'MX-ZAC'],
    [969, 'Ciudad de México', 'MX-CMX']
  ],

  citiesMap: <ClabeCitiesMap>{}
}

export default clabe
