export function toCamel(value: string): string {
  return value.replace(/([-_][a-z])/gi, (newValue) => newValue.toUpperCase().replace('-', '').replace('_', ''))
}

export function toSnake(value: string): string {
  return value.replace(/[A-Z]/g, (newValue) => `_${newValue.toLowerCase().replace(/^_/, '')}`)
}

export function capitalize(value: string): string {
  return `${value[0].toUpperCase()}${value.substring(1)}`
}

export function stripDiacritics(value: string): string {
  if (value.length === 0) {
    return value
  }

  let striped = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

  const pairs: { [key: string]: string } = {
    є: 'ie',
    Є: 'IE',
    ґ: 'gh',
    Ґ: 'GH',
    'æ|ǽ': 'ae',
    'Æ|Ǽ': 'AE',
    œ: 'oe',
    Œ: 'OE',
    ª: 'a',
    'ð|đ': 'd',
    'Ð|Đ': 'D',
    ħ: 'h',
    Ħ: 'H',
    ı: 'i',
    I: 'i',
    'ŀ|ł': 'l',
    'Ŀ|Ł': 'L',
    ŉ: 'n',
    'ø|ǿ|º': 'o',
    'Ø|Ǿ|º': 'O',
    ſ: 's',
    ŧ: 't',
    Ŧ: 'T',
    ß: 'ss',
    ĳ: 'ij',
    Ĳ: 'IJ',
    ƒ: 'f',
    Ƒ: 'F',
    þ: 'TH',
    Þ: 'th'
  }

  Object.keys(pairs).forEach((key) => {
    striped = striped.replace(new RegExp(key, 'g'), pairs[key])
  })

  return striped
}
