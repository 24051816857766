function polishIDCardCodeValidator(IDCardCode: string): boolean {
  // Check length
  if (!IDCardCode || IDCardCode.length !== 9) {
    return false
  }

  const uppercaseIDCardCode = IDCardCode.toUpperCase()
  const letterValues = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z'
  ]

  const getLetterValue = (letter: string) => {
    for (let j = 0, max = letterValues.length; j < max; j += 1) {
      if (letter === letterValues[j]) {
        return j
      }
    }

    return -1
  }

  // Check series
  for (let i = 0; i < 3; i += 1) {
    if (getLetterValue(uppercaseIDCardCode[i]) < 10) {
      return false
    }
  }
  // Check number
  for (let i = 3; i < 9; i += 1) {
    if (getLetterValue(uppercaseIDCardCode[i]) < 0 || getLetterValue(uppercaseIDCardCode[i]) > 9) {
      return false
    }
  }

  // Checksum
  const sum =
    (7 * getLetterValue(uppercaseIDCardCode[0]) +
      3 * getLetterValue(uppercaseIDCardCode[1]) +
      getLetterValue(uppercaseIDCardCode[2]) +
      7 * getLetterValue(uppercaseIDCardCode[4]) +
      3 * getLetterValue(uppercaseIDCardCode[5]) +
      getLetterValue(uppercaseIDCardCode[6]) +
      7 * getLetterValue(uppercaseIDCardCode[7]) +
      3 * getLetterValue(uppercaseIDCardCode[8])) %
    10

  return sum === getLetterValue(uppercaseIDCardCode[3])
}

export default polishIDCardCodeValidator
