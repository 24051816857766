export enum Gender {
  Female,
  Male
}

export interface Curp {
  Names: string
  DateOfBirth: Date
  Gender: Gender
  BornState: string
  CheckDigit: number
}

export function parse(curp: string): Curp | boolean {
  const reg =
    /([A-Z]{4})([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([HM])(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)([B-DF-HJ-NP-TV-Z]{3})([0-9A-Z])([0-9])/

  if (!reg.test(curp)) {
    return false
  }

  const parts = reg.exec(curp)

  if (!parts) {
    return false
  }

  let dateStr = `${parts[2]}-${parts[3]}-${parts[4]}`

  if (parts[8].match(/[a-z]/i)) {
    dateStr = `20${dateStr}`
  } else {
    dateStr = `19${dateStr}`
  }

  const dob = new Date(`${dateStr}T00:00:00Z`)

  return {
    Names: parts[1],
    DateOfBirth: dob,
    Gender: parts[5] === 'H' ? Gender.Male : Gender.Female,
    BornState: parts[6],
    CheckDigit: parseInt(parts[9], 10)
  }
}
