function getCreditCardBrand(creditCardNumber: string): string {
  // the regular expressions check for possible matches as you type, hence the OR operators based on the number of chars
  // regexp string length {0} provided for soonest detection of beginning of the card numbers this way it could be used for BIN CODE detection also

  // JCB
  const jcbRegex = new RegExp('^(?:2131|1800|35)[0-9]{0,}$') // 2131, 1800, 35 (3528-3589)
  // American Express
  const amexRegex = new RegExp('^3[47][0-9]{0,}$') // 34, 37
  // Diners Club
  const dinersRegex = new RegExp('^3(?:0[0-59]{1}|[689])[0-9]{0,}$') // 300-305, 309, 36, 38-39
  // Visa
  const visaRegex = new RegExp('^4[0-9]{0,}$') // 4
  // MasterCard
  const mastercardRegex = new RegExp('^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$') // 2221-2720, 51-55
  const maestroRegex = new RegExp('^(5[06789]|6)[0-9]{0,}$') // always growing in the range: 60-69, started with / not something else, but starting 5 must be encoded as mastercard anyway
  // Discover
  const discoverRegex = new RegExp('^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$')
  /// /6011, 622126-622925, 644-649, 65

  // get rid of anything but numbers
  const value = creditCardNumber.replace(/\D/g, '')

  // checks per each, as their could be multiple hits
  let selectedBrand = 'unknown'
  if (value.match(jcbRegex)) {
    selectedBrand = 'jcb'
  } else if (value.match(amexRegex)) {
    selectedBrand = 'amex'
  } else if (value.match(dinersRegex)) {
    selectedBrand = 'diners_club'
  } else if (value.match(visaRegex)) {
    selectedBrand = 'visa'
  } else if (value.match(mastercardRegex)) {
    selectedBrand = 'mastercard'
  } else if (value.match(discoverRegex)) {
    selectedBrand = 'discover'
  } else if (value.match(maestroRegex)) {
    if (value[0] === '5') {
      // started 5 must be mastercard
      selectedBrand = 'mastercard'
    } else {
      selectedBrand = 'maestro' // maestro is all 60-69 which is not something else, thats why this condition in the end
    }
  }

  return selectedBrand
}

export default getCreditCardBrand
