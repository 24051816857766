import _Vue from 'vue'

/**
 * Detect back navigation and make it globally available through plugin
 * @param Vue Vue instance
 */
// eslint-disable-next-line import/prefer-default-export
export function PopStatePlugin(Vue: typeof _Vue): void {
  Vue.prototype.popstate = false
  window.addEventListener('popstate', () => {
    Vue.prototype.popstate = true
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    popstate: boolean
  }
}
