import AttributionModule from '@/store/modules/attribution'
import { environment } from '@/environments/environment'
import { Device } from '@/models'

// eslint-disable-next-line camelcase
function clientData(): { client: { bundle_identifier: string; version: string }; device: Device | undefined } {
  return {
    device: AttributionModule.getDevice || undefined,
    client: {
      // @fiizy/auth package uses it so it already needs to be in camel case
      bundle_identifier: AttributionModule.getClientBundleIdentifier,
      version: environment.VERSION
    }
  }
}

export default clientData
