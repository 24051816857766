












import { Component, Prop, Vue } from 'vue-property-decorator'
import tracking, { getNewChannelHash } from '@fiizy/tracking'
import logger from '@/utils/logger'
import AttributionModule from '@/store/modules/attribution'
import { Attribution, FormRequestFields } from '@/models'
import Cookies from 'js-cookie'
import { queryParamsToObject } from '@/utils/helpers'
import { mapGetters } from 'vuex'
import NotificationsModule from '@/store/modules/notifications'

@Component({
  components: {
    ModalNotification: () => import(/* webpackChunkName: "fields" */ '@/components/fields/Notification/Modal.vue')
  },
  computed: {
    ...mapGetters('notifications', {
      notifications: 'getNotifications'
    })
  }
})
export default class App extends Vue {
  @Prop({ required: true })
  channelHash?: string

  @Prop({ required: false })
  landingPageChannelHash?: string

  @Prop({ required: false })
  seoChannelHash?: string

  @Prop({ required: false })
  forceChannel?: boolean

  @Prop({ required: true })
  language?: string

  @Prop({ default: () => 'en-US', required: true })
  locale!: string

  @Prop({ required: true })
  clientBundleIdentifier!: string

  @Prop({ required: false })
  cdnPath?: string

  @Prop({ default: false, required: false })
  fullScreen?: boolean

  @Prop({ required: false })
  form?: FormRequestFields

  created(): void {
    this.initAttribution()
  }

  initAttribution(): void {
    /* channel attribution start
     * https://fiizywiki.atlassian.net/wiki/spaces/PLATFORM/pages/40927254/Channel+Attribution
     */
    const originalChannelHash = tracking.getSavedChannel() || ''
    const channelHashes = {
      seo: this.seoChannelHash,
      saved: originalChannelHash,
      default: this.landingPageChannelHash,
      calculator: this.channelHash || ''
    }
    const urlParams = new URLSearchParams(window.location.search)

    const newChannelHash = getNewChannelHash({
      urlParams,
      referrer: document.referrer,
      channelHashes,
      forceChannel: this.forceChannel
    })
    if (!newChannelHash) {
      logger.error('newChannelHash is empty, App.vue')
    }
    /* channel attribution end */

    const attributedClientBundle =
      urlParams.get('bundle') || queryParamsToObject(Cookies.get('xq') || '').bundle || this.clientBundleIdentifier

    const attribution: Attribution = {
      channelHash: newChannelHash,
      language: this.language,
      locale: this.locale,
      clientBundleIdentifier: attributedClientBundle,
      cdnPath: this.cdnPath
    }

    AttributionModule.initAttribution(attribution)
  }

  notificationShown(notificationHash: string): void {
    NotificationsModule.patchNotificationQueueAction({
      queueHash: notificationHash,
      status: 'delivered'
    })
  }
}
