import './styles/app.scss'

import { environment } from '@/environments/environment'
import Vue from 'vue'

import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

import { applyPolyfills, defineCustomElements } from '@fiizy/stylebook/loader'
import { FormRequestFields } from '@/models'
import { objectToFormRequestFields } from '@/utils/helpers'

interface AppDataSet extends DOMStringMap {
  channelHash?: string
  landingPageChannelHash?: string
  seoChannelHash?: string
  forceChannel?: string
  locale?: string
  language?: string
  clientBundleIdentifier?: string
  loanType?: string
  cdnPath?: string
  fullScreen?: string
}

Vue.config.ignoredElements = [/fi-\w*/]

applyPolyfills().then(() => {
  defineCustomElements()
})

const mount = document.querySelectorAll(`div[${environment.ATTACH_ATTRIBUTE}]`)

mount.forEach((elem) => {
  const element = elem as HTMLElement
  const dataset = element.dataset as AppDataSet

  // read data form attributes
  const form: FormRequestFields = objectToFormRequestFields(dataset)

  new Vue({
    router,
    store,
    render: (h) => {
      return h(App, {
        props: {
          channelHash: dataset.channelHash || '',
          landingPageChannelHash: dataset.landingPageChannelHash || '',
          seoChannelHash: dataset.seoChannelHash || '',
          forceChannel: dataset.forceChannel === 'true',
          language: dataset.language || '',
          locale: dataset.locale || 'en-US',
          clientBundleIdentifier: dataset.clientBundleIdentifier || '',
          cdnPath: dataset.cdnPath || '',
          fullScreen: dataset.fullScreen === 'true',
          form
        }
      })
    }
  }).$mount(element)
})
