function polishCompanyTaxIDValidator(nip: string): boolean {
  const nipWithoutDashes = nip.replace(/-/g, '')
  const reg = /^[0-9]{10}$/

  if (!reg.test(nipWithoutDashes)) {
    return false
  }

  const dig = `${nipWithoutDashes}`.split('')

  const control =
    (6 * parseInt(dig[0], 10) +
      5 * parseInt(dig[1], 10) +
      7 * parseInt(dig[2], 10) +
      2 * parseInt(dig[3], 10) +
      3 * parseInt(dig[4], 10) +
      4 * parseInt(dig[5], 10) +
      5 * parseInt(dig[6], 10) +
      6 * parseInt(dig[7], 10) +
      7 * parseInt(dig[8], 10)) %
    11

  return parseInt(dig[9], 10) === control
}

export default polishCompanyTaxIDValidator
