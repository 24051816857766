import Vue from 'vue'
import store from '@/store'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import tracking, { getUserDeviceHash } from '@fiizy/tracking'
import Cookies from 'js-cookie'
import { Attribution, Device } from '@/models'
import { queryParamsToObject } from '@/utils/helpers'
import postMessageToReactNativeWebView from '@/utils/postMessageToReactNativeWebView'

@Module({
  dynamic: true,
  store,
  namespaced: true,
  name: 'attribution'
})
class AttributionStore extends VuexModule {
  attribution: Attribution | null = null

  device: Device | null = null

  locale = 'en-US'

  clientBundleIdentifier = ''

  cdnBaseUrl = ''

  cdnPath = ''

  get getAttribution(): Attribution {
    return { ...this.attribution, userDeviceHash: this.device ? this.device.id : '' }
  }

  get getDevice(): Device | null {
    return this.device
  }

  get getApplicationHash(): string | null {
    if (this.attribution && this.attribution.applicationHash) {
      return this.attribution.applicationHash
    }

    return null
  }

  get getCampaignHash(): string | null {
    if (this.attribution && this.attribution.campaignHash) {
      return this.attribution.campaignHash
    }

    return null
  }

  get getOnboardingHash(): string | null {
    if (this.attribution && this.attribution.onboardingHash) {
      return this.attribution.onboardingHash
    }

    return null
  }

  get getChannelHash(): string | null {
    if (this.attribution && this.attribution.channelHash) {
      return this.attribution.channelHash
    }

    return null
  }

  get getImpressionHash(): string | null {
    if (this.attribution && this.attribution.impressionHash) {
      return this.attribution.impressionHash
    }

    return null
  }

  get getProcessName(): string | null {
    if (this.attribution && this.attribution.processName) {
      return this.attribution.processName
    }
    return null
  }

  get getProcessSessionHash(): string | null {
    if (this.attribution && this.attribution.processSessionHash) {
      return this.attribution.processSessionHash
    }
    return null
  }

  get getLocale(): string {
    return this.locale
  }

  get getClientBundleIdentifier(): string {
    return this.clientBundleIdentifier
  }

  get getCdnBaseUrl(): string {
    return this.cdnBaseUrl || ''
  }

  get getCdnPath(): string {
    return this.cdnPath || ''
  }

  @Mutation
  private SET_CDN_PATH(cdnPath: string) {
    this.cdnPath = cdnPath
    this.cdnBaseUrl = cdnPath

    if (cdnPath.length > 0) {
      this.cdnBaseUrl = new URL('/', cdnPath).href.replace(/\/+$/, '')
    }
  }

  @Mutation
  private SET_DEVICE(device: Device | null) {
    this.device = device ? { ...device } : null
  }

  @Mutation
  private SET_ATTRIBUTION(attribution: Attribution) {
    const queryParams = queryParamsToObject(Cookies.get('xq') || '')

    this.attribution = attribution ? { ...attribution, queryParams } : null
  }

  @Mutation
  private SET_IMPRESSION(impressionHash: string) {
    if (this.attribution && impressionHash) {
      Vue.set(this.attribution, 'impressionHash', impressionHash)
    }
  }

  @Mutation
  private SET_LOCALE(locale: string) {
    this.locale = locale
  }

  @Mutation
  private SET_CLIENT_BUNDLE_IDENTIFIER(clientBundleIdentifier: string) {
    this.clientBundleIdentifier = clientBundleIdentifier
  }

  @Action({ commit: 'SET_ATTRIBUTION', rawError: true })
  async initAttribution(initialAttribution: Attribution): Promise<Attribution> {
    if (initialAttribution.channelHash) {
      tracking.setChannel(initialAttribution.channelHash)
    }

    if (initialAttribution.locale) {
      store.commit('attribution/SET_LOCALE', initialAttribution.locale)
    }

    if (initialAttribution.clientBundleIdentifier) {
      store.commit('attribution/SET_CLIENT_BUNDLE_IDENTIFIER', initialAttribution.clientBundleIdentifier)
    }

    if (initialAttribution.cdnPath) {
      store.commit('attribution/SET_CDN_PATH', initialAttribution.cdnPath)
    }

    await store.dispatch('attribution/loadDeviceID')

    return initialAttribution
  }

  @Action({ commit: 'SET_ATTRIBUTION' })
  setAttribution(attribution: Attribution): Attribution {
    if (attribution.channelHash) {
      tracking.setChannel(attribution.channelHash)
    }
    return attribution
  }

  @Action({ commit: 'SET_ATTRIBUTION' })
  updateAttribution(newAttribution: Attribution): Attribution {
    if (newAttribution.channelHash) {
      tracking.setChannel(newAttribution.channelHash)
    }
    return newAttribution
  }

  @Action({ commit: 'SET_IMPRESSION' })
  setImpressionHash(impressionHash: string): string | undefined {
    if (impressionHash) {
      return impressionHash
    }
    return undefined
  }

  @Action({ commit: 'SET_ATTRIBUTION' })
  resetProcess(): Attribution {
    return { ...this.attribution }
  }

  @Action({ commit: 'SET_ATTRIBUTION' })
  resetAttribution(): null {
    return null
  }

  @Action({ commit: 'SET_DEVICE' })
  async loadDeviceID(): Promise<Device> {
    const uuid = getUserDeviceHash()
    postMessageToReactNativeWebView({
      eventName: 'fi-device-hash',
      deviceHash: uuid
    })
    return { id: uuid } as Device
  }
}

const AttributionModule = getModule(AttributionStore)

export default AttributionModule
