/* eslint-disable import/no-cycle */
import apiService from '@/api'
import { FormFieldActionRequest, ObjectLiteral } from '@/models'
import logger from '@/utils/logger'
import AuthModule from '@/store/modules/auth'

export async function fetchImpressionHash(params: URLSearchParams): Promise<ObjectLiteral | undefined> {
  const resourcePath = `/impression?${params.toString()}`
  return apiService.get<ObjectLiteral>(resourcePath).catch((error: Error) => {
    logger.error(`Error loading resource ${resourcePath}`, { requestErrorObject: error })

    return undefined
  })
}

export async function postFormFieldAction(form: FormFieldActionRequest): Promise<unknown> {
  await AuthModule.isUserAuth()

  const resourcePath = '/form_field_action'

  return apiService.post(resourcePath, form, { headers: { 'Content-Type': 'text/plain' } }).catch((error: Error) => {
    logger.error(`Error loading resource ${resourcePath}`, { requestErrorObject: error })

    return undefined
  })
}
