import isValidDate from '@/utils/isValidDate'

function polishIdCodeValidator(pesel: string): boolean {
  const reg = /^[0-9]{11}$/

  if (!reg.test(pesel)) {
    return false
  }

  const dig = `${pesel}`.split('')

  let control =
    (parseInt(dig[0], 10) +
      3 * parseInt(dig[1], 10) +
      7 * parseInt(dig[2], 10) +
      9 * parseInt(dig[3], 10) +
      parseInt(dig[4], 10) +
      3 * parseInt(dig[5], 10) +
      7 * parseInt(dig[6], 10) +
      9 * parseInt(dig[7], 10) +
      parseInt(dig[8], 10) +
      3 * parseInt(dig[9], 10)) %
    10

  if (control === 0) {
    control = 10
  }

  control = 10 - control

  if (!(parseInt(dig[10], 10) === control)) {
    return false
  }

  const getYear = (year: number, month: number) => {
    if (month > 80) {
      return 1800 + year
    }

    if (month > 60) {
      return 2200 + year
    }

    if (month > 40) {
      return 2100 + year
    }

    if (month > 20) {
      return 2000 + year
    }

    return 1900 + year
  }

  const getMonth = (month: number) => {
    if (month > 80) {
      return month - 80
    }

    if (month > 60) {
      return month - 60
    }

    if (month > 40) {
      return month - 40
    }

    if (month > 20) {
      return month - 20
    }

    return month
  }

  const year = getYear(parseInt(pesel.substring(0, 2), 10), parseInt(pesel.substring(2, 4), 10))
  const month = getMonth(parseInt(pesel.substring(2, 4), 10))
  const day = parseInt(pesel.substring(4, 6), 10)

  const dob = new Date(`${year}/${month}/${day}`)

  return isValidDate(dob)
}

export default polishIdCodeValidator
