import apiService from '@/api'
import { Form, NotificationSubscribeRequest, NotificationQueueActionRequest, Notification } from '@/models'
import logger from '@/utils/logger'

export async function fetchNotificationQueue(): Promise<Notification[] | undefined> {
  const resourcePath = `/notifications/queue`
  return apiService.get<Notification[]>(resourcePath).catch((error: Error) => {
    logger.error(`Error loading resource ${resourcePath}`, { requestErrorObject: error })

    return undefined
  })
}

export async function postSubscribeAction(request: NotificationSubscribeRequest): Promise<Form | undefined> {
  const resourcePath = '/notifications/subscribe'

  return apiService.post<Form>(resourcePath, request).catch((error: Error) => {
    logger.error(`Error loading resource ${resourcePath}`, { requestErrorObject: error })

    return undefined
  })
}

export async function patchNotificationQueueAction(request: NotificationQueueActionRequest): Promise<Form | undefined> {
  const resourcePath = `/notifications/queue/${request.queueHash}`

  return apiService.patch<Form>(resourcePath, request).catch((error: Error) => {
    logger.error(`Error loading resource ${resourcePath}`, { requestErrorObject: error })

    return undefined
  })
}
