import { EventPayload, FormField } from '@/models'
import { ageFromDateOfBirthday } from '@/utils/helpers'
import { parse, Curp } from '@/utils/mx/curp'

export default function curpAgeValidator(payload: EventPayload, field: FormField): boolean | string {
  const curp = parse(field.value as string)

  if (curp === false) {
    return false
  }

  const age = ageFromDateOfBirthday((curp as Curp).DateOfBirth)

  if (age >= payload.properties?.age) {
    return true
  }

  if (payload.properties?.hint) {
    return payload.properties.hint
  }

  return false
}
