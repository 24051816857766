import { environment } from '@/environments/environment'
import { Vue } from 'vue-property-decorator'

Vue.prototype.ApiUrlState = ''

export default function apiUrl(): string {
  const mount = document.querySelector(`div[${environment.ATTACH_ATTRIBUTE}]`)
  const element = mount as HTMLElement

  if (element) {
    const { dataset } = element

    if (dataset.apiUrl) {
      Vue.prototype.ApiUrlState = dataset.apiUrl
      return Vue.prototype.ApiUrlState
    }
  }

  if (environment.BUILD_ENV === 'development' && environment.API_URL) {
    Vue.prototype.ApiUrlState = environment.API_URL
    return Vue.prototype.ApiUrlState
  }

  return Vue.prototype.ApiUrlState
}
