function postMessageToReactNativeWebView(message: { eventName: string; [key: string]: unknown }): void {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window.ReactNativeWebView) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.ReactNativeWebView.postMessage(JSON.stringify(message))
  }
}

export default postMessageToReactNativeWebView
