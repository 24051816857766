/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FormField, ObjectLiteral } from '@/models'
import { toCamel } from '@/utils/transformer'
import isValidIBAN from '@/utils/validateIBAN'
import validateSpanishIdCode from '@/utils/es/spainIdCodeValidator'
import { getEventByAction } from '@/utils/formbuilder'
import polishIdCodeValidator from '@/utils/pl/polishIdCodeValidator'
import polishIDCardCodeValidator from '@/utils/pl/polishIDCardCodeValidator'
import polishCompanyTaxIDValidator from '@/utils/pl/polishCompanyTaxIDValidator'
import mexicoBankAccountNumberValidator from '@/utils/mexicoBankAccountNumberValidator'
import getCreditCardBrand from '@/utils/getCreditCardBrand'
import isValidSpainCompanyIdCode from '@/utils/es/spainCompanyIdCodeValidator'
import curpAgeValidator from '@/utils/mx/curpAgeValidator'
import { generateFieldEventPayload } from '@/utils/helpers'
import polishIdCodeAgeValidator from '@/utils/pl/polishIdCodeAgeValidator'

function validateAge(value: string, field: FormField) {
  if (field?.properties?.minAge) {
    const date = new Date(value).getTime()

    // 31557600000 is 1 year in ms if you wonder :)
    const age = Math.floor((Date.now() - date) / 31557600000)

    return age >= field.properties.minAge
  }

  return true
}

function validateStringValue(value: number | string | boolean, field: FormField): boolean {
  let regexValidation = true
  let olderThanMinAge = true
  if (field.regexValidation) {
    const pattern = new RegExp(field.regexValidation)

    regexValidation = pattern.test(value as string)
  }

  if (field.properties?.minAge) {
    olderThanMinAge = validateAge(value as string, field)
  }

  if (getEventByAction(field, 'polish_identity_card')) {
    return polishIDCardCodeValidator(value as string)
  }

  if (getEventByAction(field, 'nip')) {
    return polishCompanyTaxIDValidator(value as string)
  }

  return regexValidation && olderThanMinAge
}

function validateNumericValue(value: number | string | boolean, field?: FormField): boolean {
  let regexValidation = true
  let valueBiggerThanMaxValue = false
  let valueBiggerThanMinValue = false

  if (field?.regexValidation) {
    const pattern = new RegExp(field.regexValidation)
    regexValidation = pattern.test(value as string)
  }

  if (field?.properties?.max) {
    valueBiggerThanMaxValue = value > parseInt(field.properties.max, 10)
  }

  if (field?.properties?.min) {
    valueBiggerThanMinValue = value < parseInt(field.properties.min, 10)
  }

  return regexValidation && !(valueBiggerThanMaxValue || valueBiggerThanMinValue)
}

function validateBooleanValue(value: number | string | boolean, regex?: string): boolean {
  if (regex) {
    const pattern = new RegExp(regex)

    return pattern.test(value as string)
  }

  return typeof value === 'boolean' && value
}

function validateKontomatikValue(value: number | string | boolean, field: FormField): boolean {
  // kontomatik field might have string value for value if it uses external client
  // ex: value = `${session.sessionId}|${session.sessionIdSignature}`
  const event = getEventByAction(field, 'success')
  if (event?.dataSource?.externalClient) {
    return validateStringValue(value, field)
  }

  return validateBooleanValue(value, field.regexValidation)
}

function fieldValueExists(value: string, field: FormField): boolean {
  const selectEvents = field.events?.filter((e) => e.action === 'autocomplete.select')
  let propertyName = 'value'
  if (selectEvents) {
    selectEvents.forEach((selectEvent) => {
      if (selectEvent.dataSource && selectEvent.dataSource.fields) {
        selectEvent.dataSource.fields.forEach((dataSourceField) => {
          if (dataSourceField.field === field.fieldKey) {
            propertyName = toCamel(dataSourceField.value)
          }
        })
      }
    })
  }
  const option = field.values?.find((fieldValuesValue) => fieldValuesValue[propertyName] === value)

  // prefill fills field with value but not with values
  if (field.properties?.disabled) {
    return true
  }

  if (option && option.customValue && field.regexValidation) {
    const pattern = new RegExp(field.regexValidation)

    return pattern.test(option.value as string)
  }

  return !!option
}

function isValidPin(value: string, field: FormField) {
  return value.length === (parseInt(field.properties?.pinLength, 10) || 4)
}

function isValidIdCode(value: string, field: FormField) {
  if (getEventByAction(field, 'dni')) {
    return validateSpanishIdCode(value as string).valid
  }

  if (getEventByAction(field, 'pesel')) {
    return polishIdCodeValidator(value as string)
  }

  if (getEventByAction(field, 'cif')) {
    return isValidSpainCompanyIdCode(value as string)
  }

  return validateStringValue(value, field)
}

function isValidIdCodeAge(value: string, field: FormField): { valid: boolean; notice?: string } {
  const peselAge = getEventByAction(field, 'pesel_age')
  if (peselAge) {
    const ageValid = polishIdCodeAgeValidator(generateFieldEventPayload(peselAge.action, peselAge), field)

    if (ageValid !== true) {
      return { valid: false, notice: ageValid as string }
    }
  }

  const curpAge = getEventByAction(field, 'curp_age')
  if (curpAge) {
    const ageValid = curpAgeValidator(generateFieldEventPayload(curpAge.action, curpAge), field)

    if (ageValid !== true) {
      return { valid: false, notice: ageValid as string }
    }
  }

  return { valid: true }
}

function validateReturnType(value: number | string | boolean, expectedType: string): number | string | boolean {
  if (typeof value === expectedType) {
    return value
  }
  if (expectedType === 'number') {
    const parsed = parseInt(value as string, 10)

    if (Number.isNaN(parsed)) {
      return 0
    }

    return parsed
  }
  if (expectedType === 'boolean') {
    return /true/i.test(value as string)
  }

  return value as string
}

function getFormFieldReturnType(fieldType: string): string {
  const returnTypes: ObjectLiteral = {
    integer: 'number',
    range: 'number',
    dineo_bank_reader: 'boolean',
    instantor: 'boolean',
    kontomatik: 'kontomatik',
    checkbox: 'boolean',
    radio_boolean: 'boolean',
    toggle_button: 'string',
    radio_button: 'string',
    onfido: 'boolean',
    date: 'string',
    select_autocomplete: 'values',
    select_fetch: 'values',
    dropdown_searchable: 'values',
    select_simple: 'values',
    button_collection: 'values',
    iban: 'iban',
    mx_bank_account: 'mxBankAccount',
    verification_pin: 'verification_pin',
    id_code: 'id_code',
    file: 'boolean',
    default: 'string'
  }

  return returnTypes[fieldType] || returnTypes.default
}

export default function validateFormField(field: FormField): FormField {
  const validatedField = {
    ...field,
    isValid: false
  } as FormField

  const isRequired = !field.optional && !field.displayElement && field.fieldType !== 'address_autocomplete'

  // might be false or 0 and still valid
  if ((field.value === undefined || field.value === '') && isRequired) {
    return validatedField
  }

  // dont validate empty and not required field
  if (!field.value && !isRequired) {
    validatedField.isValid = true
    validatedField.hasError = false

    return validatedField
  }

  // initially when API sends hasError then don't validate
  // field as valid even when validation on frontend is correct
  // NB! hasError is sent in to this function as false(in process.ts setFieldValue action) -
  // on initial render it is not set only when user changes field value
  if (field.hasError && !field.isValid) {
    validatedField.isValid = false
    validatedField.hasError = true

    return validatedField
  }

  // expected return type, will attempt cast to type
  const fieldReturnType = getFormFieldReturnType(field.fieldType)

  validatedField.value = validateReturnType(field.value!, fieldReturnType)

  switch (fieldReturnType) {
    case 'string':
      validatedField.isValid = validateStringValue(validatedField.value!, field)
      break
    case 'number':
      validatedField.isValid = validateNumericValue(validatedField.value!, field)
      break
    case 'boolean':
      validatedField.isValid = validateBooleanValue(validatedField.value!, field.regexValidation)
      break
    case 'values':
      validatedField.isValid = fieldValueExists(validatedField.value as string, field)
      break
    case 'iban':
      validatedField.isValid = isValidIBAN(validatedField.value as string)
      break
    case 'mxBankAccount':
      if (
        getCreditCardBrand(validatedField.value as string) !== 'unknown' &&
        validatedField.value.toString().length <= 16
      ) {
        validatedField.isValid = false
        validatedField.customErrorMsg =
          'Parece que has ingresado los datos de una tarjeta de crédito o débito - por favor ingresa los datos de tu cuenta / por favor ingresa la CLABE de tu cuenta!'
      } else {
        validatedField.isValid = mexicoBankAccountNumberValidator(validatedField.value as string)
        validatedField.customErrorMsg = ''
      }
      break
    case 'verification_pin':
      validatedField.isValid = isValidPin(validatedField.value as string, field)
      break
    case 'id_code':
      validatedField.isValid = isValidIdCode(validatedField.value as string, field)

      if (validatedField.isValid) {
        const result = isValidIdCodeAge(validatedField.value as string, field)
        validatedField.isValid = result.valid
        validatedField.noticeMsg = result.notice
      }
      break
    case 'kontomatik':
      validatedField.isValid = validateKontomatikValue(validatedField.value as string, field)
      break
    default:
      validatedField.isValid = validateStringValue(validatedField.value!, field)
      break
  }

  validatedField.hasError = !validatedField.isValid

  return validatedField
}
