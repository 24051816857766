const DNI_REGEX = /^(\d{8})([A-Z])$/
const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/

const spainIdCodeType = (value: string): string | null => {
  if (value.match(DNI_REGEX)) {
    return 'dni'
  }

  if (value.match(NIE_REGEX)) {
    return 'nie'
  }

  return null
}

const isValidDNI = (dni: string): boolean => {
  const dniLetter = 'TRWAGMYFPDXBNJZSQVHLCKE'
  const letter = dniLetter.charAt(parseInt(dni, 10) % 23)

  return letter === dni.charAt(8)
}

const isValidNIE = (nie: string): boolean => {
  // Change the initial letter for the corresponding number and validate as DNI
  const niePrefix = nie.charAt(0)

  const prefixes: { [char: string]: string } = {
    X: '0',
    Y: '1',
    Z: '2'
  }

  return isValidDNI(`${prefixes[niePrefix] ? prefixes[niePrefix] : nie.charAt(0)}${nie.substring(1)}`)
}

const validateSpanishIdCode = (value: string): { type: string | null; valid: boolean } => {
  // Ensure upcase and remove whitespace
  const uppercaseValue = value.toUpperCase().replace(/\s/, '')

  const type = spainIdCodeType(uppercaseValue)

  // eslint-disable-next-line no-unused-vars
  const validators: { [char: string]: (idCodeValue: string) => boolean } = {
    dni: isValidDNI,
    nie: isValidNIE
  }

  const valid = type && validators[type] ? validators[type](uppercaseValue) : false

  return {
    type,
    valid
  }
}

export default validateSpanishIdCode
