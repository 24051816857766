/* eslint-disable import/no-cycle */
import Router, { Route } from 'vue-router'
import logger from '@/utils/logger'
import FormRequest from '@/models/formrequest'
import { hasProcessParams } from '@/utils/helpers'
import Vue from 'vue'
import redirectHome from '@/utils/events/redirectHome'
import AttributionModule from '@/store/modules/attribution'
import ProcessModule from '@/store/modules/process'

async function navigateBack(currentRoute: Route) {
  if (!hasProcessParams(currentRoute)) {
    return Promise.reject(new Error('Application params not found'))
  }

  const attribution = {
    processSessionHash: currentRoute.params.processSessionHash,
    ...AttributionModule.getAttribution
  }

  const formRequest: FormRequest = {
    attribution,
    step: { previous: true }
  }

  const form = await ProcessModule.postProcess(formRequest)

  if (!form) {
    return Promise.reject(new Error('Back navigation form not found'))
  }

  return form
}

function routeHandler(router: Router) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async (to: Route, from: Route, next: any): Promise<void> => {
    const popState = router.app.popstate
    // load the previous form if it is not the calculator
    if (popState) {
      Vue.prototype.popstate = false

      navigateBack(from)
        .then((form) => {
          if (form.step?.properties.layout === 'calculator' && from.name !== 'calculator') {
            // next({ name: 'calculator', query: from.query })
            window.scrollTo(0, 0)
            next(false)
          } else {
            window.scrollTo(0, 0)
            next(false)
          }
        })
        .catch((error) => {
          logger.error(error)
          redirectHome()
        })
    } else {
      Vue.prototype.popstate = false
      next()
    }
  }
}

export default routeHandler
