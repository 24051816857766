/* eslint-disable import/no-cycle */
import ProcessModule from '@/store/modules/process'
import AuthModule from '@/store/modules/auth'

async function logout(): Promise<void> {
  ProcessModule.setLoading(true)
  AuthModule.authLogout()
    .then(() => {
      window.location.href = '/'
    })
    .catch(() => {
      ProcessModule.setLoading(false)
    })
}

export default logout
