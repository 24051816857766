import { EventPayload, FormField } from '@/models'
import { ageFromDateOfBirthday } from '@/utils/helpers'
import { parse, Pesel } from '@/utils/pl/pesel'

export default function polishIdCodeAgeValidator(payload: EventPayload, field: FormField): boolean | string {
  const pesel = parse(field.value as string)

  if (pesel === false) {
    return false
  }

  const age = ageFromDateOfBirthday((pesel as Pesel).DateOfBirth)

  if (age >= payload.properties?.age) {
    return true
  }

  if (payload.properties?.hint) {
    return payload.properties.hint
  }

  return false
}
