import { Route } from 'vue-router'
import FormField from '@/models/formfield'
import { isArray } from '@/utils/typeguards'
import { EventPayload, FieldEvent, FormFieldActionEvent } from '@/models'
import logger from '@/utils/logger'
import FormRequestFields from '../models/formrequestfield'

// register form keys our cms is sending us without form prefix, this is legacy and should be removed in the future
// so that all form data is prefixed with form. prefix
const formKeys = [
  'loanType',
  'insuranceType',
  'applicationPeriod',
  'applicationPrincipal',
  'firstName',
  'lastName',
  'email',
  'phoneMobile',
  'confirmTerms',
  'confirmCreditorTerms',
  'confirmDataProcessing',
  'confirmExternalMarketing',
  'confirmWhatsapp'
]
// find a better way to handle numeric field prefill, simple regexp digits check will not work for phone number field
// back wants a string there
const formKeysNumeric = ['applicationPeriod', 'applicationPrincipal']

export function isAndroidMobileDevice(): boolean {
  return !!(navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/Windows Phone/i))
}

export function isAppleMobileDevice(): boolean {
  return !!(
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i)
  )
}

export function isMobile(): boolean {
  return isAndroidMobileDevice() || isAppleMobileDevice()
}

export function hasProcessParams(currentRoute: Route): boolean {
  if (!currentRoute.params || !currentRoute.params.processSessionHash) {
    return false
  }

  return true
}

export function findFieldIndex(fieldKey: string, fields: FormField[]): number {
  return fields.findIndex((field) => field.fieldKey === fieldKey)
}

export function getThousandSeparator(lang?: string): string {
  switch (lang) {
    case 'en-US':
    case 'en-GB':
    case 'es-MX':
      return ','
    case 'es-ES':
    case 'pt-BR':
    case 'es-CO':
    case 'es':
      return '.'
    case 'pl-PL':
    case 'vi-VN':
      return ' '
    default:
      logger.warn('getThousandSeparator missing lang', { lang })
      return ' '
  }
}

export function getRadix(lang?: string): string {
  switch (lang) {
    case 'en-US':
    case 'en-GB':
    case 'es-MX':
      return '.'
    case 'es-ES':
    case 'pl-PL':
    case 'pt-BR':
    case 'vi-VN':
    case 'es-CO':
    case 'es':
      return ','
    default:
      logger.warn('getRadix missing lang', { lang })
      return ','
  }
}

export function replacePlaceholders(
  value: string | number | string[] | number[] | undefined,
  format: string = '',
  regex?: RegExp
): string {
  const placeholder: RegExp = regex || /(\{{\w+?\}})/g
  if (!value) {
    return ''
  }
  const matches = format.match(placeholder)
  if (!matches) {
    return value.toString()
  }

  return matches.reduce((prev, match, i) => {
    if (isArray(value)) {
      return prev.replace(match, value[i].toString())
    }
    return prev.replace(match, value.toString())
  }, format)
}

export function generateFieldEventPayload(
  sourceEvent: string,
  fieldEvent: FieldEvent,
  fieldKey?: string,
  value?: string | number | boolean
): EventPayload {
  const payload = {
    action: sourceEvent,
    event: fieldEvent.action,
    origin: fieldKey,
    key: fieldEvent.key || fieldKey,
    value,
    properties: fieldEvent.dataSource
  } as EventPayload

  // Handle event payload overrides
  if (fieldEvent.dataSource) {
    // allow to change the actual event that is being called
    if (fieldEvent.dataSource.event) {
      payload.event = fieldEvent.dataSource.event
    }

    // allow to change the event field value
    if (fieldEvent.dataSource.value !== undefined) {
      payload.value = fieldEvent.dataSource.value
    }

    // Events can have rule
    if (fieldEvent.dataSource.rule) {
      payload.rules = [fieldEvent.dataSource.rule]
    }

    // Events can have multiple rules
    if (fieldEvent.dataSource.rules) {
      payload.rules = fieldEvent.dataSource.rules
    }
  }

  return payload
}

export function queryParamsToObject(searchString: string): { [x: string]: string } {
  if (!searchString) {
    return {}
  }
  const searchParams = searchString.split('?')[1]

  if (!searchParams) {
    return {}
  }

  const result: { [x: string]: string } = {}
  const paramParts = searchParams.split('&')
  for (const part of paramParts) {
    const paramValuePair = part.split('=')
    const [key, value] = paramValuePair
    if (key.indexOf('form.') < 0) {
      if (paramValuePair.length === 2) {
        try {
          result[key] = decodeURIComponent(value.replace(/\+/g, ' '))
        } catch (e) {
          logger.error('Can not decode uri component', { key, value, searchString })
          result[key] = value
        }
      } else {
        result[key] = ''
      }
    }
  }

  return result
}

export function loadScript(url: string): Promise<HTMLScriptElement> {
  const script = document.createElement('script')

  script.type = 'text/javascript'
  script.async = false
  script.src = url

  return new Promise<HTMLScriptElement>((resolve, reject) => {
    script.onload = () => resolve(script)
    script.onerror = () => reject(new Error(`Could not load script: ${url}`))
    document.head.appendChild(script)
  })
}

export function removeScript(filename: string): void {
  const elements = document.getElementsByTagName('script')

  for (let i = elements.length; i >= 0; i -= 1) {
    if (
      elements[i] &&
      elements[i].getAttribute('src') !== null &&
      elements[i].getAttribute('src')?.indexOf(filename) !== -1
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      elements[i].parentNode.removeChild(elements[i])
    }
  }
}

export function getFieldInvalidValue(event: FormFieldActionEvent): string | null {
  let incorrectValue = null

  if (event.action === 'shown') {
    return incorrectValue
  }

  if (
    (!event.field.isValid || event.field.fieldType === 'verification_pin') &&
    typeof event.field.value !== 'undefined'
  ) {
    incorrectValue = event.field.value.toString()
  }

  if (typeof event.field.value === 'undefined') {
    incorrectValue = null
  }

  if (typeof event.value !== 'undefined') {
    incorrectValue = event.value
  }

  return incorrectValue
}

export function isElementInViewport(el: Element | null): boolean {
  if (!el || el.nodeType !== 1) {
    return false
  }
  const html = document.documentElement
  const r = el.getBoundingClientRect()

  return !!r && r.bottom >= 0 && r.right >= 0 && r.top <= html.clientHeight && r.left <= html.clientWidth
}

export function focusFirstInvalidField(): void {
  const invalidFields = [...document.querySelectorAll('.error')]

  if (invalidFields && invalidFields.length) {
    const firstInvalidFieldInput = invalidFields[0].querySelector('input:not([disabled])') as HTMLInputElement

    const firstInvalidFieldSelect = invalidFields[0].querySelector('button')
    const firstInvalidNestedInput = invalidFields[0]
      .querySelector('.error')
      ?.querySelector('input:not([disabled])') as HTMLInputElement

    if (firstInvalidNestedInput) {
      firstInvalidNestedInput.focus()
    } else if (firstInvalidFieldInput) {
      firstInvalidFieldInput.focus()
    } else if (firstInvalidFieldSelect) {
      firstInvalidFieldSelect.click()
    }
  }
}

export function ageFromDateOfBirthday(value: Date | string): number {
  const today = new Date()
  let birthDate = value

  if (!(birthDate instanceof Date)) {
    birthDate = new Date(birthDate)
  }

  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1
  }

  return age
}

export function objectToFormRequestFields(
  data: { [key: string]: string | (string | null)[] } | DOMStringMap
): FormRequestFields {
  const form: FormRequestFields = {}

  Object.entries(data).forEach(([key, value]) => {
    if (key.indexOf('form') !== 0 && formKeys.indexOf(key) < 0) return
    let field = key.replace(/^(form\.?)/, '')
    field = field[0].toLowerCase() + field.slice(1)

    // we need boolean to be a real boolean, not string, same thing for numeric fields
    if (value === 'true') {
      form[field] = true
    } else if (value === 'false') {
      form[field] = false
    } else if (formKeysNumeric.indexOf(field) >= 0) {
      form[field] = +(value as string)
    } else {
      form[field] = value as string
    }
  })

  return form
}
