/* eslint-disable import/no-cycle */
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'
import { Attribution, FormFieldActionRequest, FormFieldActionEvent } from '@/models'
import { fetchImpressionHash, postFormFieldAction } from '@/api/resources/tracking'
import tracking from '@fiizy/tracking'
import { environment } from '@/environments/environment'
import AttributionModule from '@/store/modules/attribution'
import { getFieldInvalidValue } from '@/utils/helpers'
import ProcessModule from '@/store/modules/process'
import { eventbus, eventBusEvents } from '@fiizy/utils'


const bus = eventbus(environment.EVENT_CHANNEL || 'c432556660722ff93cefb5a932e550b7')
const { impressionCreated } = eventBusEvents

@Module({
  dynamic: true,
  store,
  namespaced: true,
  name: 'tracking'
})
class TrackingStore extends VuexModule {
  impressionHash: string | null = null

  campaignHash: string | null = null

  actionQueue: FormFieldActionRequest[] = []

  googleClickIdentifier: string | null = null

  get getImpressionHash(): string | null {
    return this.impressionHash
  }

  get getCampaignHash(): string | null {
    return this.campaignHash
  }

  get getGoogleClickIdentifier(): string | null {
    return this.googleClickIdentifier
  }

  @Mutation
  private SET_IMPRESSION_HASH(impressionHash: string) {
    this.impressionHash = impressionHash
  }

  @Mutation
  private SET_CAMPAIGN_HASH(campaignHash: string) {
    this.campaignHash = campaignHash
  }

  @Mutation
  private QUEUE_REQUEST(request: FormFieldActionRequest) {
    if (this.actionQueue.findIndex((req) => req === request) === -1) {
      this.actionQueue.push(request)
    }
  }

  @Mutation
  private EMPTY_ACTION_QUEUE() {
    this.actionQueue = []
  }

  @Mutation
  private SET_GOOGLE_CLICK_IDENTIFIER(glcid: string) {
    this.googleClickIdentifier = glcid
  }

  @Action({ commit: 'SET_IMPRESSION_HASH' })
  setImpressionHash(impressionHash: string): string {
    return impressionHash
  }

  @Action({ commit: 'SET_CAMPAIGN_HASH' })
  setCampaignHash(campaignHash: string): string {
    return campaignHash
  }

  @Action({ commit: 'SET_GOOGLE_CLICK_IDENTIFIER' })
  setGoogleClickIdentifier(gclid: string): string {
    return gclid
  }

  @Action({ rawError: true, commit: 'SET_IMPRESSION_HASH' })
  async fetchImpressionHash(attribution: Attribution): Promise<string | null> {
    const timeStamp = Date.now().toString()
    const urlParams = new URLSearchParams(window.location.search)
    const originalChannelHash = tracking.getSavedChannel()

    if (originalChannelHash && attribution.channelHash) {
      tracking.invalidatePublisherParams(originalChannelHash, attribution.channelHash)
    }

    tracking.setGoogleClickIdentifier(urlParams)

    const impressionParams = new URLSearchParams()

    if (attribution.language) {
      impressionParams.set('lang', attribution.language)
    }
    if (attribution.channelHash) {
      impressionParams.set('channel_hash', attribution.channelHash)
    }
    if (attribution.userDeviceHash) {
      impressionParams.set('user_device_hash', attribution.userDeviceHash)
    }
    impressionParams.set('document_referrer', document.referrer)
    impressionParams.set('document_location', document.location.href)
    impressionParams.set('client_milliseconds_since_epoch', timeStamp)
    impressionParams.set('type', 'page_load')
    if (attribution.clientBundleIdentifier) {
      impressionParams.set('client_bundle_identifier', attribution.clientBundleIdentifier)
    }
    impressionParams.set('client_version', environment.VERSION)

    const gaClientID = tracking.getAnalyticsClientID()

    if (gaClientID) {
      impressionParams.set('analytics_client_id', gaClientID)
    }

    const gClickID = tracking.getGoogleClickIdentifier()

    if (gClickID) {
      impressionParams.set('google_click_id', gClickID)
      store.dispatch('tracking/setGoogleClickIdentifier', gClickID)
    }

    const res = await fetchImpressionHash(impressionParams)

    if (res) {
      if (res.formValidationToken) {
        store.commit('process/SET_FORM_VALIDATION_TOKEN', res.formValidationToken)
      }

      if (res.impressionHash) {
        store.dispatch('attribution/setImpressionHash', res.impressionHash)
        await store.dispatch('tracking/postActionQueue', res.impressionHash)
        bus.emit(impressionCreated, { impression_hash: res.impressionHash })
        return res.impressionHash
      }
    }

    return this.impressionHash
  }

  @Action
  async postActionQueue(impressionHash: string): Promise<void> {
    const requests = this.actionQueue.map(async (action) => {
      action.impressionHash = impressionHash
      return postFormFieldAction(action)
    })
    return Promise.all(requests).then(() => {
      store.commit('tracking/EMPTY_ACTION_QUEUE')
    })
  }

  @Action
  async postFormFieldAction(event: FormFieldActionEvent): Promise<unknown> {
    if (!event || !event.action || !event.field) {
      return undefined
    }

    const request = {
      formProcess: AttributionModule.getProcessName,
      processSessionHash: AttributionModule.getProcessSessionHash,
      actionType: event.action.toString(),
      formFieldName: event.field.fieldKey,
      step: event.field.step,
      orderInStep: event.field.order,
      rowInStep: event.field.row,
      clientMillisecondsSinceEpoch: Date.now(),
      wasOptionalForClient: event.field.optional || false,
      incorrectValue: getFieldInvalidValue(event),
      fieldInFocusDuration: event.fieldInFocusDuration,
      valueAfterTransformation: event.field.valueAfterTransformation,
      valueBeforeTransformation: event.field.valueBeforeTransformation,
      viewCollectionAbbreviation: ProcessModule.getStep?.viewCollectionAbbreviation,
      viewCollectionStep: ProcessModule.getStep?.viewCollectionStep,
      viewAbbreviation: ProcessModule.getStep?.viewAbbreviation,
      viewStep: ProcessModule.getStep?.viewStep,
      flowAbbreviation: ProcessModule.getStep?.flowAbbreviation
    } as unknown as FormFieldActionRequest

    if (this.campaignHash) {
      request.campaignHash = this.campaignHash
    }

    if (!this.getImpressionHash) {
      // put it in queue to execute later once impression hash has been fetched
      store.commit('tracking/QUEUE_REQUEST', request)
      return undefined
    }
    request.impressionHash = this.getImpressionHash

    return postFormFieldAction(request)
  }
}

const TrackingModule = getModule(TrackingStore, store)

export default TrackingModule
