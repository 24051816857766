import { Logger } from '@fiizy/utils/dist/logger'
import { environment } from '@/environments/environment'

const logger = new Logger({
  releaseStage: environment.BUILD_ENV,
  apiKey: environment.BUGSNAG_API_KEY,
  appVersion: environment.VERSION,
  // that [1] is paylands specific error for breadcrumbs
  // as it contains sensitive data we ignore it
  // filters doc: https://docs.bugsnag.com/platforms/javascript/configuration-options/#filters
  filters: ['[1]', /^password$/i, /^cc_/]
})

export default logger
